import React from 'react'
import { Container, Box, Button, IconButton } from '@mui/material/'
import Logo from '../../Assets/bayley.png'
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink, Outlet } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { HexColorPicker, HexColorInput } from "react-colorful";

function SideMenu() {
    let activeStyle = {
        textDecoration: "underline",
      };
    
      let activeClassName = "underline";

  return (
    <div>
        <Box sx={{ backgroundColor: 'white', justifyContent: 'center', height: '100px', top: 0, width: '100%', display: 'flex', alignItems: 'center', p: 4, boxSizing: 'border-box', boxShadow: 1}}>
            <Box sx={{ width: '1200px', justifyContent: 'space-between', display: 'flex', alignItems: 'center', boxSizing: 'border-box'}}>
                <Box sx={{ display: 'flex' }}>
                    <img src={Logo} width='75px' style={{ paddingRight: '30px'}}/>
                    <Button href='/bayley' sx={{ color: '#101010', textTransform: 'none'}}>
                        Users
                    </Button>
                    <Button href='/bayley/library' sx={{ color: '#101010', textTransform: 'none'}}>
                        Video Library
                    </Button>
                    <Button href='/bayley/inductions' sx={{ color: '#101010', textTransform: 'none'}}>
                        Inductions
                    </Button>
                </Box>
                <Box>
                    <Button href='/' startIcon={<LogoutIcon />} sx={{ color: '#101010', textTransform: 'none'}}>
                    Logout
                    </Button>
                </Box>
            </Box>
            

        </Box>
        <Outlet />
    </div>
  )
}

export default SideMenu