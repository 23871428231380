import Close from '@mui/icons-material/Close'
import { Box, Breadcrumbs, Button, Checkbox, IconButton, Paper, TextField, Typography, Link, Modal, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Snackbar, Alert, Fab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle';
import { Check } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DataGrid, GridRowId, GridColumnApi, GridCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import { set } from 'lodash';

function InductionV2() {
    const [row, setRow] = useState([]);
    const [form, setForm] = useState([]);
    const [questions, setQuestions] = useState([]);
    const params = useParams();
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
      // Creating Induction Error Alert
      const [open5, setOpen5] = React.useState(false);
      const handleOpen5 = () => setOpen5(true);
      const handleClose5 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen5(false);
      };
            // Creating Induction Error Alert
            const [open6, setOpen6] = React.useState(false);
            const handleOpen6 = () => setOpen6(true);
            const handleClose6 = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpen6(false);
            };
          // Creating Induction Error Alert
          const [open8, setOpen8] = React.useState(false);
          const handleOpen8 = () => setOpen8(true);
          const handleClose8 = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
        
            setOpen8(false);
          };

          const [open9, setOpen9] = React.useState(false);
          const handleOpen9 = () => setOpen9(true);
          const handleClose9 = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
           
            setOpen9(false);
          };


      // Creating Induction Error Alert
      const [open7, setOpen7] = React.useState(false);
      const handleOpen7 = () => setOpen7(true);
      const handleClose7 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen7(false);
      };
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => {
    setOpen2(false)
    val.length = 0
    };
    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => {
    
      if(localStorage.getItem("clicked_question_id")){
        console.log(localStorage.getItem("clicked_question_id"))
      setOpen3(true);
    }else{
    
      setOpen9(true);
    }
  }
    const handleClose3 = () => {
       localStorage.removeItem("clicked_question_id");
      setOpen3(false);
      setCheckboxSelection3(false);
      val2.length = 0;
      };
    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const [open10, setOpen10] = useState(false);
    const handleOpen10 = () => setOpen10(true);
    const [open11, setOpen11] = useState(false);
    const handleClose11 = () => setOpen11(false);
    const handleOpen11 = () => setOpen11(true);

    const [open12, setOpen12] = useState(false);
    const handleClose12 = () => {
      localStorage.removeItem("clicked_question_id")
      setOpen12(false);};
    const handleOpen12 = () => {
      console.log(localStorage.getItem("clicked_question_id"))
      if(localStorage.getItem("clicked_question_id") == null){
        setOpen13(true)
      }else{
        setOpen12(true);
   
      }
      
    }

    const [open13, setOpen13] = useState(false);
    const handleClose13 = () => setOpen13(false);
    const handleOpen13 = () => {
     
        setOpen13(true);
     
     
      
    }

    const [selectionModel, setSelectionModel] = React.useState([]);
    const prevSelectionModel = React.useRef(selectionModel);
  
    const [deleteId, setDeleteID] = useState("")
    const handleClose10 = () => setOpen10(false);
    const org = localStorage.getItem("org");
    const [newText, setnewText] = useState("")
    const [newAnswer, setNewAnswerText] = useState("")
    const [questionid, setQuestionID] = useState([])
    const [answerid, setAnswerID] = useState("")
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isSubscribed3, setIsSubscribed3] = useState(false);
    const [val,setVal]=useState([]);
    const [val2,setVal2]=useState([]);
    const [val3,setVal3]=useState([]);
    const [checked, setChecked] = useState([]);
    const [checked3, setChecked3] = useState([]);
    const [checkboxSelection, setCheckboxSelection] = React.useState(true);
    const [checkboxSelection3, setCheckboxSelection3] = React.useState(true);
    const [questionss, setQuestionss] = useState([]);
    const [questionAnswers, setQuestionAnswers] = useState([])
    
    const handleRowClick = (params) => {
      console.log(params.row.question_id)
      localStorage.setItem("clicked_question_id", params.row.question_id)
        setQuestionss(params.row)
        setQuestionAnswers(params.row.answer_text.split(","))
        console.log(params.row.answer_text.split(","))
      
      };

      const handlecheckBoxSelected = (params) => {
        console.log(params.row)
       
        };

   

    const handleAdd=()=>{
        const abc=[...val,[]]
        setVal(abc)
    }
    const handleAdd2=()=>{
      const abc=[...val,[]]
      setVal2(abc)
  }
  const handleChange3=(onChangeValue,i)=>{
    const inputdata=[...val]
    inputdata[i]=onChangeValue.target.value;
    setVal3(inputdata)
   }
  const handleChange2=(onChangeValue,i)=>{
    const inputdata=[...val]
    inputdata[i]=onChangeValue.target.value;
    setVal2(inputdata)
   }

    const handleChange=(onChangeValue,i)=>{
     const inputdata=[...val]
     inputdata[i]=onChangeValue.target.value;
     setVal(inputdata)
    }
    const handleDelete=(i)=>{
        const deletVal=[...val]
        deletVal.splice(i,1)
        setVal(deletVal)  
    }
    const handleDelete2=(i)=>{
      const deletVal=[...val]
      deletVal.splice(i,1)
      setVal2(deletVal)  
  }

  const handleDelete3=(i)=>{
    console.log(questionAnswers)
   
    
    console.log("hello")
    console.log(i)
    const deletVal3=[...val]
    console.log(deletVal3)
    questionAnswers.splice(i,1)
    setVal3(deletVal3)  
}

 

    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }
    
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        width: {
            xs: 300,
            sm: 450,
          },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };
    
    const getUser = async () => {
        try{
            console.log(params.induction)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/induction/${params.induction}}`
            );
            const jsonData = await response.json();
         
       
            setRow(jsonData);
          console.log(jsonData)
 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getForms = async (video) => {
        try{
            console.log(params.video)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/organswer/${video}`
            );
            const jsonData = await response.json();
                console.log(jsonData)
            setForm(jsonData);
            setOpen10(true)

 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };
      
      const Testing = (event) => {
        event.preventDefault();
        console.log(val.length);
        if (newText.trim().length <= 0 ) {
            console.log('input value is empty');
            setOpen5(true)
        
        } 

        else if (val.length === 0) {
            setOpen8(true)
        }

        else {
            var answerlength = 0
            var answerchecked = 0
            val.toString().split(',').map(function (answer){
                if (answer.length > 0) {
                    answerlength += 1
                    if (checked.includes(answer)) {
                        answerchecked += 1
                    }

                    else {

                    }
                }

                else {
                    setOpen6(true)
                }
            })
            console.log(answerlength);
            console.log(answerchecked);
            console.log(val.length);
            if (answerlength === val.length) {
                if (answerchecked >= 1) {
                    createQuestion();
                }

                else {
                    setOpen7(true) 
                }
            }

            else {
            }
        
        }
    }


    
    const Testing2 = (event) => {
      event.preventDefault();
      console.log(val3.length);
    

     
          var answerlength = 0
          var answerchecked = 0
          val3.toString().split(',').map(function (answer){
              if (answer.length > 0) {
                  answerlength += 1
                  if (answer === checked3) {
                      answerchecked += 1
                  }

                  else {

                  }
              }

              else {
                  setOpen6(true)
              }
          })
          console.log(answerlength);
          console.log(answerchecked);
          console.log(val3.length);
          if (answerlength === val3.length) {
              
            getQuestionID2();
              

             
          }

          else {
          }
      
      
  }

      const createQuestion = async() => {
    
        try {
            const org_id = localStorage.getItem("org_id");
            const induction_id = (params.induction);
            const body = { newText, induction_id , org_id };
       
          const response = await fetch(
            `${localStorage.getItem("url")}/api/addQuestion`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
          getQuestionID();
      

        } catch (err) {
          console.log(err.message);
        }
      };

      const saveQuestion = async() => {
    
        try {
            const org_id = localStorage.getItem("org_id");
            const induction_id = (params.induction);
            const body = { newText, induction_id , org_id };
       
          const response = await fetch(
            `${localStorage.getItem("url")}/api/addQuestion`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
          getQuestionID2();
      

        } catch (err) {
          console.log(err.message);
        }
      };


      const getQuestionID = async() => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/latestquestion`
            );
            const jsonData = await response.json();
            console.log(jsonData);
          
            console.log(jsonData[0].question_id)
           
            createAnswer(jsonData[0].question_id);
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getQuestionID2 = async() => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/latestquestion`
            );
            const jsonData = await response.json();
            console.log(jsonData);
          
            console.log(jsonData[0].question_id)
           
            createAnswer2(localStorage.getItem("clicked_question_id"));
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };
      

      

      const ref = React.useRef(null);

      const handletest = () => {
        const element = ref.current;
        console.log(element);
        console.log(element.id);
      };
      
      const createAnswer = async (question_id) => {
        console.log(val)
        var is_correct = 0;
        val.toString().split(',').map(async function (answer){
            console.log(question_id)

            try {
                if(checked.includes(answer)){
                    is_correct = 1;
                }else{
                    is_correct = 0;
                }
                const body = { answer, question_id, is_correct };
                console.log(question_id)
           
              const response = await fetch(
                `${localStorage.getItem("url")}/api/addAnswer`,
                {
                  method: "POST",
                  headers: { "content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
    
              window.location.reload()
    
            } catch (err) {
              console.log(err.message);
            }
        })
        
     }

     const createAnswer2 = async (question_id) => {
      console.log(val2)
      var is_correct = 0;
      val2.toString().split(',').map(async function (answer){
          console.log(question_id)

          try {
              if(answer === checked){
                  is_correct = 1;
              }else{
                  is_correct = 0;
              }
              const body = { answer, question_id, is_correct };
              console.log(question_id)
         
            const response = await fetch(
              `${localStorage.getItem("url")}/api/addAnswer`,
              {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
  
            window.location.reload()
  
          } catch (err) {
            console.log(err.message);
          }
      })
      
   }


     const createCorrect = async () => {
            {
                const answer_id = (answerid);
                const is_correct = 1;
                const body = { answer_id, is_correct };
                const response = await fetch(
                  `${localStorage.getItem("url")}/api/updateUser}`,
                  {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(body),
                  }
                );
    
              console.log("TESTST");
        
    
            }

        

     }

     const addAnswer = async (question_id) => {
      console.log(val)
      var is_correct = 0;
      val.toString().split(',').map(async function (answer){
          console.log(question_id)

          try {
              if(answer === checked){
                  is_correct = 1;
              }else{
                  is_correct = 0;
              }
              const body = { answer, question_id, is_correct };
              console.log(question_id)
         
            const response = await fetch(
              `${localStorage.getItem("url")}/api/addAnswer`,
              {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
  
            window.location.reload()
  
          } catch (err) {
            console.log(err.message);
          }
      })
      
   }

   const addCorrect = async () => {
          {
              const answer_id = (answerid);
              const is_correct = 1;
              const body = { answer_id, is_correct };
              const response = await fetch(
                `${localStorage.getItem("url")}/api/updateUser}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
  
            console.log("TESTST");
      
  
          }

      

   }



        const setQuestion = (question) => {
           handleOpen3();
            setQuestionID(question)
            console.log(question)
        }
      
        const setCorrect2 = (answer) => {
             setAnswerID(answer)
             console.log(answer)
             createCorrect();
         }

         const handleChecked = event => {
            if (event.target.checked) {
              console.log("yes")
              checked.push(event.target.id)
              console.log(event.target.id)
            } else{
              console.log("no")
              const index = checked.indexOf(event.target.id)
              checked.splice(index,1)
              console.log(checked)
            }
            setIsSubscribed(current => !current);
          };
          const handleChecked2 = event => {
            console.log("hi")
            if (event.target.checked) {
              setChecked(event.target.id)
              console.log(event.target.id)
            } 
            setIsSubscribed(current => !current);
          };
          const handleChecked3 = event => {
            if (event.target.checked) {
              console.log("yes")
              checked3.push(event.target.id)
              console.log(event.target.id)
            } else{
              console.log("no")
              const index = checked3.indexOf(event.target.id)
              checked3.splice(index,1)
              console.log(checked3)
            }
            setIsSubscribed3(current => !current);
          };

      
          const deleteQuestion = async() => {
    
            try {
              
                const org_id = localStorage.getItem("org_id");
                const induction_id = (params.induction);
                const question_id = localStorage.getItem('clicked_question_id')
                const body = { induction_id , org_id, question_id };

                const response = await fetch(
                  `${localStorage.getItem("url")}/api/deleteAnswer`,
                  {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify(body),
                  }
                );

              const response2 = await fetch(
                `${localStorage.getItem("url")}/api/deleteQuestion`,
                {
                  method: "POST",
                  headers: { "content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
            
              window.location.reload()
    
            } catch (err) {
              console.log(err.message);
            }
          };

          


          
    useEffect(() => {
        getUser();
       
       
      
       }, []);
       

       const columns = [
        { 
            field: 'question_text', 
            headerName: 'Question', 
            width: 650,
        },
        { 
            field: 'correct_answer', 
            headerName: 'Correct Answer', 
            width: 300,
        },
        {   
    
            field: 'actions',
            type: 'actions',
            width: 80,
           
            renderCell: (params) => {
              const onClickEdit = async () => {
             
                setQuestionss(params.row)
                setQuestionAnswers(params.row.answer_text.split(","))
                console.log(params.row.answer_text.split(","))
                setOpen3(true)
              };
        
              return (
                <>
                  <IconButton onClick={onClickEdit}>
                    <EditIcon />
                  </IconButton>
                </>
              );
            },
          },

      ];


  return (
    <Box 
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                Please enter the question text.
            </Alert>
        </Snackbar>
        <Snackbar open={open6} autoHideDuration={6000} onClose={handleClose6}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose6}>
                Answers must not be blank.
            </Alert>
        </Snackbar>
        <Snackbar open={open7} autoHideDuration={6000} onClose={handleClose7}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose7}>
                Please select an answer as correct.
            </Alert>
        </Snackbar>
        <Snackbar open={open8} autoHideDuration={6000} onClose={handleClose8}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose8}>
                Please add an answer.
            </Alert>
        </Snackbar>
        <Snackbar open={open9} autoHideDuration={6000} onClose={handleClose9}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose9}>
                Please select a question to change.
            </Alert>
        </Snackbar>
        <Snackbar open={open13} autoHideDuration={6000} onClose={handleClose13}>
            <Alert  severity="error" sx={{ width: '100%' }} onClose={handleClose13}>
                Please select a question to Delete
            </Alert>
        </Snackbar>
        <Box 
            sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href={`/test/users`}>
                    Dashboard
                </Link>
                <Link underline="hover" color="inherit" href={`/test/inductions`}>
                    Inductions
                </Link>
            </Breadcrumbs>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: {
                    xs: 'flex-start',
                    sm: 'flex-end'
                },
                marginBottom: 2,
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                }
                }}>
                <Typography  
                    variant="h4" 
                    sx={{ 
                        fontWeight: '600',
                        fontSize: {
                            xs: 25,
                            md: 30,
                        },
                        }}
                >
                    {row.length ? row[0].induction_title : "loading.."}
                </Typography>

                <Box sx={{
                    display: {
                        xs: 'none',
                        md: 'block',
                    }
                }}>
                    <Button variant='contained' onClick={setOpen2} sx={{mr: 2}}>add question</Button>
                    <Button variant='contained' onClick={setOpen3} sx={{mr: 2}}>Edit question</Button>
                    <Button variant="contained" onClick={handleOpen12}>
                    Delete Question
                </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Paper>
                        <DataGrid
                    
                        sx={{ border: '0px'}}
                        rows={row || {id: "loading"}}
                        columns={columns}
                        pageSize={10}
                        experimentalFeatures={{ newEditingApi: true }}
                        autoHeight
                      
                    
                        disableMultipleRowSelection={true}
                        onRowClick={handleRowClick} 
                        getRowId={(row) =>  generateRandom()}
                    />
                </Paper>
                <Box>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                    pt: 2,
                }}>
                    <Fab size="small" color="primary" aria-label="add" sx={{mr: 2, zIndex: 1}} onClick={setOpen2}>
                        <Add />
                    </Fab>
                    <Fab size="small" color="primary" aria-label="add" sx={{zIndex: 1}}>
                        <EditIcon />
                    </Fab>
                </Box>

                </Box>
                <Box>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                    pt: 2,
                }}>
                    <Fab size="small" color="primary" aria-label="add" sx={{mr: 2, zIndex: 1}} onClick={setOpen3}>
                        <Add />
                    </Fab>
                    <Fab size="small" color="primary" aria-label="add" sx={{zIndex: 1}}>
                        <EditIcon />
                    </Fab>
                </Box>

                </Box>
            </Box>
        </Box>
        <Modal
                open={open10}
                onClose={() => setOpen10(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    {form.map((post) => (
                    <Box width='100%' sx={{display: 'flex', mb: 1}} key={post.answer_id}>
                        <Checkbox value={post.answer_id} defaultChecked={post.is_correct == 1 ? true : false} onChange={(e) => setCorrect2(e.target.value)}sx={{ width: '10px', mr: 2}}/>
                        <TextField 
                          
                            sx={{ width: '1100px', mr: 2}} 
                            size='small' 
                            id="outlined" 
                            variant="outlined" 
                            placeholder='Answer Text'
                            defaultValue={post.answer_text}
                        />

                        <IconButton onClick={handleOpen4}>
                            <Close />
                        </IconButton>
                    </Box>
                    ))}
                    
                </Box>
            </Modal>
        <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Question & Answers</Typography>
                        <Close onClick={handleClose2} sx={{cursor: 'pointer'}}/>
                    </Box>
                        <Box align="left" mb={2}>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Question Text'
                                defaultValue=''
                                onChange={(e) => setnewText(e.target.value)}
                        />
                        </Box>
                        <Box>
                        {val.map((data,i)=>{
                                return(
                                <Box sx={{mb : 1}}>
                                    <Checkbox id={data} onChange={handleChecked}/>
                                    <TextField 
                                        sx={{ width: {
                                            xs: '65%',
                                            sm: '75%',
                                        }
                                    }} 
                                        size='small' 
                                        id="outlined" 
                                        variant="outlined" 
                                        placeholder='Answer Text'
                                        defaultValue=''
                                        value={data} 
                                        onChange={e=>handleChange(e,i)} 
                                    />
                                        <IconButton onClick={()=>handleDelete(i)}>
                                            <Close />
                                        </IconButton>
                                </Box>
                                )
                            })}
                        </Box>
                        <Box>
                            <Button variant='contained' onClick={()=>handleAdd()}>Add Answer</Button>
                        </Box>
                        <Box align='right'>
                            <Button onClick={Testing} variant='contained'>add</Button>
                            
                        </Box>
                </Box>
        </Modal>
        
        <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                    <Box sx={styleModal}>
                    <Box  sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>{questionss.question_text}</Typography>
                        <Close onClick={handleClose3} sx={{cursor: 'pointer'}}/>
                    </Box> 
                    
                    {questionAnswers.map((q,i) => {
                        
                       
                         return(
                         
                            <Box align="left" mb={{mb : 1}}>
                            <Checkbox id={q} defaultChecked={questionss.correct_answer.includes(q) ? true : false } onChange={handleChecked3}/>
                            <TextField 
                            id={q}
                            sx={{ width: '100%' }} 
                            size='small' 
                           
                            variant="outlined" 
                          
                            
                            defaultValue={q}
                            onChange={e => setNewAnswerText(e.target.value,i)}
                    />
                      <IconButton onClick={()=>handleDelete3(i)}>
                                            <Close />
                                        </IconButton>
                         </Box>     )
                          
                           
                 
                    })}
                        
                        
                         
                        <Box align='right'>
                            <Button variant='contained' onClick={()=>handleAdd2()} sx={{mr: 2}}>add</Button>
                            <Button variant='contained' onClick={Testing2}>save</Button>
                        </Box>
                </Box>
        </Modal>
        <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this answer?"}
            </DialogTitle>
            <DialogActions >
            <Button onClick={handleClose4}>No</Button>
            <Button onClick={handleClose4} autoFocus>
                Yes
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={open12}
            onClose={handleClose12}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this question?"}
            </DialogTitle>
            <DialogActions >
            <Button onClick={handleClose12}>No</Button>
            <Button onClick={deleteQuestion} autoFocus>
                Yes
            </Button>
            </DialogActions>
        </Dialog>

    </Box>
  )
}

export default InductionV2
