import React, { Fragment,useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert, Fab, CircularProgress } from '@mui/material/'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Add, Close, DeleteOutline } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import Moment from 'moment';


function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <GridToolbarQuickFilter sx={{ width: '100%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function InductionGroup() {
    const navigate = useNavigate();
    const [category, setNewCategory] = useState("");
  const [row, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [group, setGroup] = useState([]);
  const [videos, setVideos] = useState([]);
  const [newUser, setGroupUser] = useState([]);
  const [userToRemove, setUserToRemove] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);

  const updateGroupUser = (id) => {
    console.log(id)
    setGroupUser(newUser => [...newUser, id])
  }

      // Creating Induction Error Alert
      const [open4, setOpen4] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
      const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
            // Creating Induction Error Alert
            const [open5, setOpen5] = React.useState(false);
            const handleOpen5 = () => setOpen5(true);
            const handleClose5 = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpen5(false);
            };
  const org = localStorage.getItem('org');
  const id = localStorage.getItem('induction_group_id');
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDesc, setNewCategoryDesc] = useState("");
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateCategoryDesc, setUpdateCategoryDesc] = useState("");
  const [categoryinfo, setCategoryInfo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const params = useParams();

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        sm: 450,
      },
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

const getUser = async (group) => {
     

  console.log(group);
  navigate(`${id}`)
 
 

}

  const columns = [
    { 
      field: 'name', 
      headerName: 'Full Name', 
      width: 300,
  },
  { 
    field: 'completed', 
    headerName: 'Inductions Completed', 
    width:300,
},
{ 
  field: 'count', 
  headerName: 'Total Inductions', 
  width: 300,
},
  {   
    
    field: 'actions',
    type: 'actions',
    width: 80,
   
    renderCell: (params) => {
      const onClickEdit = async () => {
        setCategoryInfo(params.row);
        setUpdateCategoryName(params.row.category_name);
        setUpdateCategoryDesc(params.row.category_desc);
        setOpen2(true);
      };
      if ( group[0].status == 1 ) {
        return 
      }

      else {
        return (
          <>
          <IconButton onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
        </>
      )
      }
    },
  },


  ];
  

  
  const getGroup = async () => {
    try{
        console.log(params.id)
        const response = await fetch(
            `${localStorage.getItem("url")}/api/group/${params.id}}`
        );
        const jsonData = await response.json();
     
   
        setGroup(jsonData);
        console.log(jsonData[0])
        localStorage.setItem("induction_group_id", jsonData[0].category_id)
      console.log(jsonData)

        
       
        
    } catch (err){
        console.log(err.message);
    }
    
   
  };

  const addGroup = async (e) => {
    console.log(newUser)
    e.preventDefault();

    const users = [];

    newUser.map((user) => {
      users.push(user.id)
      console.log(user)
    })

    if(users === ""){
        setOpen4(true);
      }

    else {
            const group_id = (params.id);
            const body = { users, group_id };
   
      const response = await fetch(
        `${localStorage.getItem("url")}/api/addUserGroup`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );
        setOpen1(false);
        getGroupUsers();
        window.location.reload();
    }
  };

  const getUsers = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/users/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setUsers(jsonData);
        console.log(jsonData)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  
  const getGroupUsers = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/getGroupUsers/${params.id}`
        );
        const jsonData = await response.json();
          console.log(jsonData)
        setRows(jsonData);
   
       
        
    } catch (err){
        console.log(err.message);
    }
  };

  const updateCategory = async (e) => {


    e.preventDefault();
    if (updateCategoryName.length > 0 && updateCategoryDesc.length > 0) {
       
        try {

          const body = { updateCategoryName,updateCategoryDesc };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/updateCategory/${categoryinfo.category_id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      }

      else {
        setOpen5(true);
      }

};

function generateRandom() {
  var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const handleRowClick = (params) => {
  console.log(params.row)
  setUserToRemove(params.row.user_id)
  
  };

  const deleteQuestion = async() => {
    
    try {
      
        console.log(userToRemove)
        const group_id = (params.id);
        const user_id = userToRemove;
        const body = { group_id , user_id };

        const response = await fetch(
          `${localStorage.getItem("url")}/api/removeUser`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );

    
    
      window.location.reload()

    } catch (err) {
      console.log(err.message);
    }
  };

  const startInduction = () => {
    console.log(id)

    navigate(`${id}`)
  }

  useEffect(() => {
    getGroup();
    getUsers();
    getGroupUsers();
  
   }, []);


  return (
    <Box     
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please fill out all fields to create a Category.
              </Alert>
            </Snackbar>
            <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                Please fill out all fields to update a Category.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    {group.length 
                    ?
                        <Box sx={{display: 'flex'}}>
                            <Typography  variant="h4" sx={{ fontWeight: '600', pr: 2}}>Company ID:</Typography>
                            <Typography  variant="h4" sx={{ fontWeight: '600', pr: 2}}>{group[0].org_id}</Typography>
                            <Typography  variant="h4" sx={{ fontWeight: '600', pr: 2}}>Group ID:</Typography>
                            <Typography  variant="h4" sx={{ fontWeight: '600'}}>{group[0].id}</Typography>

                        </Box>
                    

                    :
                    <Typography  variant="h4" sx={{ fontWeight: '600'}}>loading..</Typography>
                }
                </Box>
                <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex',
                    },
                }}
                >
                    Add
                </Button>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={handleOpen1} sx={{zIndex: 1}}>
                        <Add />
                    </Fab>
                </Box>
            </Box>
            {group.length 
                    ?
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                <DataGrid
                            sx={{ border: '0px'}}
                            rows={row || {id: "loading"}}
                            columns={columns}
                            getRowId={(row) => row.id}
                           
                           
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{  Toolbar: QuickSearchToolbar }}
                            
                            disableMultipleRowSelection={true}
                            onRowClick={handleRowClick} 
                          

                        />
                        </Paper>
                    :
                    <CircularProgress color="inherit" />
            }
            
            <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
            
            {group.length 
                    ?
                      group[0].status === 0
                      ?                  
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant='contained' onClick={deleteQuestion} sx={{height: '40px', mb: 10, display: { xs: 'none', sm: 'block' },}} >Remove user</Button>
                      </Box>
                      :
                      <Box />
                    :
                    <Box />
                }
                </Box>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Add User</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 2 }}>
                            <Typography>User</Typography>
                            <Box sx={{ pb: 3}}>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setGroupUser(b)}
                                options={users}
                                getOptionLabel={(users) => users.name }
                                multiple
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.id}
                                    placeholder='User'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                
                                )}
                                
                                
                            />
                           
                          
                           
                       </Box>
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={addGroup}>ADD</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Category Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose2}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 2 }}>
                            <Typography>Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setUpdateCategoryName(e.target.value)}
                                defaultValue={categoryinfo.category_name}
                                name={categoryinfo.category_name}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setUpdateCategoryDesc(e.target.value)}
                                defaultValue={categoryinfo.category_desc}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={updateCategory}>Update</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
    </Box>
  )
}

export default InductionGroup
