import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import { useNavigate, useParams } from 'react-router';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function LibraryV2() {
    const [row, setRows] = useState([]);
    const [form, setForm] = useState([]);
    const params = useParams();
    const org = localStorage.getItem('org');

    const getOrgUsers = async (video_id) => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/test/${localStorage.getItem("org_id")}`
            );
            const jsonData = await response.json();
                console.log(jsonData);
            setRows(jsonData);
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getForms = async (video) => {
        try{
            console.log(params.video)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/orgvideo/${video}`
            );
            const jsonData = await response.json();
                console.log(jsonData)
            setForm(jsonData);
            setOpen(true)

 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };
      

      useEffect(() => {
        getOrgUsers();
       }, []);

  const [category, setAge] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: 300,
      sm: 500,
      md: 800,
      lg: 1000,
      xl: 1200,
  }, 
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
  };

  const styleModal = {
    position: 'absolute',
    borderRadius: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: '70vh',
    padding: 6,
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
    boxSizing: 'border-box'
  };

  {/* OLD SEARCH
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(row);
 
  // exclude column list from filter
  const excludeColumns = ["video_id", "video_path", "thumbnail_path", "org_id",];
 
  // handle change event of search input
  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };
 
  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toString().toLowerCase().trim();
    if (lowercasedValue === "") setData(row);
    else {
      const filteredData = row.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }
*/}

    const [query, setQuery] = useState("")

    row.sort(function (a, b) {
      if (a.video_title < b.video_title) {
        return -1;
      }
      if (a.video_title > b.video_title) {
        return 1;
      }
      return 0;
    });

    const content =  row.filter(post => {
          if (query === '') {
            return post;
          } else if (post.video_title.toLowerCase().includes(query.toLowerCase())) {
            return post;
          }
        }).map((post) => ( 
  
    <Grid xs={12} sm={6} md={6} lg={4} key={post.video_id}>

        <Card onClick={() => (getForms(post.video_id)) } >
            <CardActionArea >
                <Box 
                sx={{ 
                  width: '100%', 
                  height: {
                    xs: '200px',
                    sm: '200px',
                    md: '250px',
                    lg: '300px',
                  }, 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center' 
                }} >
                <PlayArrowRoundedIcon sx={{ position: 'absolute', fontSize: 100, color: 'white', filter: 'drop-shadow(0px 0px 20px rgb(10,10,10,0.5))'}}/>
                <img src={post.thumbnail_path} width='100%' height='100%' style={{ objectFit: 'cover', objectPosition: 'center' }}/>

                </Box>
                
                <CardContent
                  sx={{ 
                    height: {
                      xs: '150px',
                      sm: '150px',
                      md: '120px',
                      lg: '150px',
                    }, 
                  }} >
                <Typography gutterBottom variant="h5" 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    fontSize: {
                      xs: '20px',
                      md: '24px',
                    },
                    }}>
                    {post.video_title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {post.video_description}
                </Typography>
                </CardContent>
            </CardActionArea>
        </Card>

    </Grid>
    
  ));

                    

  return (
    <Box 
    sx={{
      pl: {
          xs: '0px',
          lg: '60px',
      },
      pt: {
          xs: 6,
          lg: 0,
      },  
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      boxSizing: 'border-box'
  }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href={`/test/users`}>
                    Dashboard
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Typography  variant="h4" 
                  sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}
                    >Video Library</Typography>
            </Box>
            
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '4px 4px 0 0'}}>
                    <Box align="left" sx={{mb: 3, width: '100%'}}>
                        <TextField 
                            sx={{ width: '100%', backgroundColor: 'white'}} 
                            size='small' 
                            id="outlined"
                            variant="outlined"
                            onChange={event => setQuery(event.target.value)}
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
             <Grid container spacing={2} sx={{ pb: 4}}>
                {content}
            </Grid>
          
        </Box>
        <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalVideo}>
                  <IconButton onClick={() => setOpen(false)} sx={{position: 'absolute', zIndex: 10, top: 0, right: 0, }}>
                      <CloseRoundedIcon sx={{ fontSize: 40, color: 'white' }}/>
                    </IconButton>
                  <Box sx={{ position: 'relative', paddingTop: '56.25%'}}>
                    {form.map((post) => (
                        <ReactPlayer
                      key={post.video_path}
                      className='react-player'
                      url={post.video_path}
                      width='100%'
                      height='100%'
                      controls={true}
                      style={{ position: 'absolute', top: '0', left: '0' }}
                    />
                    ))}
                  </Box>

                </Box>
            </Modal>
    </Box>
  )
}

export default LibraryV2
