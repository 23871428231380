import React, { Fragment,useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert, Fab } from '@mui/material/'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Add, Close, DeleteOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <GridToolbarQuickFilter sx={{ width: '100%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function Category() {
    const navigate = useNavigate();
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [row, setRows] = useState([]);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);

      // Creating Induction Error Alert
      const [open4, setOpen4] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
      const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
            // Creating Induction Error Alert
            const [open5, setOpen5] = React.useState(false);
            const handleOpen5 = () => setOpen5(true);
            const handleClose5 = (event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
          
              setOpen5(false);
            };
  const org = localStorage.getItem('org');

  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDesc, setNewCategoryDesc] = useState("");
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [updateCategoryDesc, setUpdateCategoryDesc] = useState("");
  const [categoryinfo, setCategoryInfo] = useState("");




  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        sm: 450,
      },
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const top100Films = [
    { title: 'Example Video #1'},
    { title: 'Example Video #2'},
    { title: 'Example Video #3'},
    { title: 'Example Video #4'},
    { title: 'Example Video #5'},
  ]

  const categoryAuto = [
    { title: 'Orchard'},
    { title: 'Packhouse'},
    { title: 'Shop'},
    { title: 'Paddock'},
  ]

  const columns = [
    { 
        field: 'category_name', 
        headerName: 'Title', 
        width: 300,
    },
    { 
      field: 'category_desc', 
      headerName: 'Description', 
      width: 700,
  },
  {   
    
    field: 'actions',
    type: 'actions',
    width: 80,
   
    renderCell: (params) => {
      const onClickEdit = async () => {
        setCategoryInfo(params.row);
        setUpdateCategoryName(params.row.category_name);
        setUpdateCategoryDesc(params.row.category_desc);
        setOpen2(true);
      };

      return (
        <>
          <IconButton onClick={onClickEdit}>
            <EditIcon />
          </IconButton>
        </>
      );
    },
  },


  ];
  

  
  const getCategories = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/categories2/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setRows(jsonData);
        console.log(jsonData)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  const addCategory = async (e) => {
    e.preventDefault();

    if(newCategoryName === ""){
        setOpen4(true);
      }

    else {

            const org = localStorage.getItem("org_id")
            const body = { newCategoryName, newCategoryDesc, org };
   
      const response = await fetch(
        `${localStorage.getItem("url")}/api/addCategory`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      window.location.reload();
    }
  };

  const updateCategory = async (e) => {


    e.preventDefault();
    if (updateCategoryName.length > 0 && updateCategoryDesc.length > 0) {
       
        try {

          const body = { updateCategoryName,updateCategoryDesc };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/updateCategory/${categoryinfo.category_id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      }

      else {
        setOpen5(true);
      }

};


  useEffect(() => {
    getCategories();
   
   
  
   }, []);


  return (
    <Box     
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please fill out all fields to create a Category.
              </Alert>
            </Snackbar>
            <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                Please fill out all fields to update a Category.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" 
                    sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}>
                        Categories</Typography>
                </Box>
                <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex',
                    },
                }}
                >
                    Add
                </Button>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={handleOpen1} sx={{zIndex: 1}}>
                        <Add />
                    </Fab>
                </Box>
            </Box>
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                <DataGrid
                            sx={{ border: '0px'}}
                            rows={row || {id: "loading"}}
                            columns={columns}
                            pageSize={10}
                            getRowId={(row) => row.category_id}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{  Toolbar: QuickSearchToolbar }}
                        />
            </Paper>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Category Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 2 }}>
                            <Typography>Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setNewCategoryName(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setNewCategoryDesc(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={addCategory}>Create</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1}}>
                        <Typography variant='h6'>Category Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose2}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 2 }}>
                            <Typography>Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setUpdateCategoryName(e.target.value)}
                                defaultValue={categoryinfo.category_name}
                                name={categoryinfo.category_name}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setUpdateCategoryDesc(e.target.value)}
                                defaultValue={categoryinfo.category_desc}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={updateCategory}>Update</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
    </Box>
  )
}

export default Category
