import React from 'react'
import { Box, Button, IconButton, Tooltip } from '@mui/material/'
import Logo from '../../Assets/logoiconwhite.png'
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Outlet } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { BarChart, Logout, PlayArrow, School } from '@mui/icons-material';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import CategoryIcon from '@mui/icons-material/Category';
import GroupsIcon from '@mui/icons-material/Groups';

function SideMenu() {

    const org = localStorage.getItem("org");


    const check_admin = () => {
        if (localStorage.getItem("admin") == "1") {
          return true;
        } else {
          return false;
        }
      };

      

  

      const LogOut = async () => {
        try {
          const response = await fetch(`${localStorage.getItem("url")}/api/logout`);
    
            localStorage.clear();
          localStorage.removeItem("authenticated");
       

        
    
          window.location = "../";
        } catch (err) {
          console.log(err.message);
        }
      };

    let activeStyle = {
        textDecoration: "underline",
      };
    
      let activeClassName = "underline";

 

    if (localStorage.getItem("access_level") === '3' && localStorage.getItem("authenticated") === "true") {
        return (
      <div>
        <Box sx={{ zIndex: 999, position: 'fixed', backgroundColor: 'white', height: '100vh', width: '60px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', boxSizing: 'border-box', boxShadow: 1}}>
              
            <Box sx={{backgroundColor: '#66BC44', paddingY: 3, display: 'flex', justifyContent: 'center'}}>
              <img src={Logo} width='60%'/>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, alignItems: 'center', width: '100%'}}>
                <Tooltip title="Dashboard" placement="right">
                  <IconButton href={`/infinite`}>
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Video Library" placement="right">
                  <IconButton href={`/infinite/library`}>
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
                
            
            <Box  sx={{ position: 'absolute', bottom: '0', paddingY: 4}}>
                <Tooltip title="Logout" placement="right">
                  <IconButton onClick={LogOut}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
            </Box>
            </Box>
        </Box>
        <Outlet />
    </div>
  )
    }else if(localStorage.getItem("access_level") === '1' && localStorage.getItem("authenticated") === "true"){
        return (
          <div>
          <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', backgroundColor: '#66BC44', paddingTop: 3}}>
              <Box sx={{             
                width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            },  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <IconButton href={`/`}>
                  <img src={Logo} width="50px" height="50px" style={{cursor: 'pointer', objectFit: 'contain'}}/>
                </IconButton>
                  
                  <Box>
                      <IconButton sx={{ color: 'white'}} onClick={LogOut}>
                          <Logout />
                      </IconButton>
                  </Box>
              </Box>
          </Box>
          <Outlet />
      </div>
  )
    }else if(localStorage.getItem("authenticated") === "true"){
        return (
            <div>
        <Box 
        sx={{ 
          zIndex: 999, 
          position: 'fixed', 
          backgroundColor: 'white', 
          height: {
            xs: '60px',
            lg: '100vh',
          },
          width: {
            xs: '100vw',
            lg: '60px',
          }, 
          display: 'flex', 
          flexDirection: {
            xs: 'row',
            lg: 'column',
          }, 
          alignItems: {
            xs: 'center',
            lg: 'flex-start'
          }
          , 
          boxSizing: 'border-box', 
          boxShadow: 1
          }}>
              
            <Box 
            sx={{
              backgroundColor: '#66BC44', 
              boxSizing: 'border-box',
              display: 'flex', 
              justifyContent: 'center',
              width: {
                xs: '80px',
                lg: '100%',
              },
              height: {
                xs: '100%',
                lg: '70px',
              },
            }}>
              <img src={Logo} width='60%' style={{objectFit: 'contain'}}/>
            </Box>
            <Box 
              sx={{ 
                display: 'flex', 
                boxSizing: 'border-box',
                pt: {
                  xs: 0,
                  lg: 2,
                },
                flexDirection: {
                  xs: 'row',
                  lg: 'column',
                }, 
                pl: {
                  xs : 1,
                  sm: 2,
                  lg: 0,
                },
                alignItems: 'center', 
                width: '100%'
              }}>
                <Tooltip title="Dashboard" placement="right">
                  <IconButton href={`/users`}>
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Video Library" placement="right">
                  <IconButton href={`/library`}>
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Inductions" placement="right">
                  <IconButton href={`/inductions`}>
                    <School />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Categories" placement="right">
                  <IconButton href={`/categories`}>
                    <CategoryIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Tracker" placement="right">
                  <IconButton href={`/tracker`}>
                    <BarChart />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Induction Group" placement="right">
                  <IconButton href={`/group`}>
                    <GroupsIcon />
                  </IconButton>
                </Tooltip>
            <Box  
            sx={{ 
              position: 'absolute', 
              bottom: {
                lg: 0,
              }, 
              right: {
                xs: 0,
                lg: 'auto',
              },
              paddingY: 4,
              pr: {
                xs: 1,
                sm: 2,
                lg: 0,
              },
            }}>
                <Tooltip title="Logout" placement="right">
                  <IconButton onClick={LogOut}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
            </Box>
            </Box>
        </Box>
        <Outlet />
    </div>
  )
    }
    
}

export default SideMenu
