import React, { useEffect, useRef, useState } from 'react';
import emailjs, { send } from '@emailjs/browser';
import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, Modal, Paper, Snackbar, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import Logo from '../../Assets/Logo.png'
import md5 from "md5";
import sha1 from "sha1";
import { useParams } from 'react-router-dom';
import Moment from 'moment-timezone';
import { useNavigate } from 'react-router';


function addHours(date, hours) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
}

function Reset() {
    const navigate = useNavigate()
    const params = useParams();
    const [user, setUser] = useState([])
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState([])
    const [updatepassword1, setUpdatedPassword1] = useState("");
    const [updatepassword2, setUpdatedPassword2] = useState("");
    const current = new Date();
    const date = Moment(current).format('YYYY-MM-DD hh:mm:ss');
    const [date_token, setTokenDate] = useState([])
    const [open3, setOpen3] = React.useState(false);
    // Creating Incorrect Password Alert
    const [open9, setOpen9] = React.useState(false);
    const handleOpen9 = () => setOpen9(true);
    const handleClose9 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen9(false);
    };

    const theme = createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: '#66BC44',
          contrastText: 'rgba(255,255,255,0.87)',
        },
        secondary: {
          main: '#f50057',
        },
      },
    });

    const styleModal = {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxSizing: 'border-box',
      width: {
          xs: 300,
          sm: 450,
        },
      bgcolor: 'white',
      borderRadius: '4px',
      boxShadow: 24,
      p: 4,
    };

      const getUser = async () => {
        console.log(params.token)
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/user_token/${params.token}`
            );
            const jsonData = await response.json();
            
            setUser(jsonData);
            console.log(jsonData)
            console.log(Moment(user[0].token_date).tz('Pacific/Auckland'))
            setTokenDate(user[0].token_date)
        } catch (err){
            console.log(err.message);
        }
      };

      const updatePassword = async (e) => {
        e.preventDefault();
        if (firstname === "") {
          setOpen9(true);
        }
        else if (lastname === "") {
          setOpen9(true);
        }
           else if (updatepassword1 == updatepassword2){
            try {
              const hash_password = md5(sha1(updatepassword1));
              const body = { hash_password, firstname, lastname };
              console.log(body)
              const response = await fetch(
                `${localStorage.getItem("url")}/api/user_update/${user[0].id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              setOpen3(true);
            } catch (err) {
              console.error(err.message);
            }
           } else {
            setOpen9(true);
           }
    };

    const completeUser = () => {
      navigate("/")
     
     
    
  }
    
    useEffect(() => {
      getUser();
     
     
    
     }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <Snackbar open={open9} autoHideDuration={6000} onClose={handleClose9} sx={{ ml: 7 }}>
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose9}>
            Please fill out all fields.
          </Alert>
        </Snackbar>
        <Paper 
        sx={{
          width: {
            xs: 300,
            xl: 400,
          },
          alignItems: 'center', 
          justifyContent: 'center', 
          display: 'flex', 
          flexDirection: 'column', 
          boxSizing: 'border-box', 
          padding: {
            xs: 4,
            xl: 10,
          }, 
          mb: 15
          }}>
            <Box
            sx={{
              mb: {
                xs: 2,
              },
            }}>
              <img src={Logo} width='100%'/>
            </Box>
            {user.length > 0 ?
            <Box>
              {Moment(date).isBefore(Moment(user[0].token_date).tz('Pacific/Auckland')) == true ?
              <Box>
 <Box sx={{ width: '100%'}}>
                <Typography>First Name</Typography>
            </Box>
            <TextField 
                sx={{ width: '100%', pb: 2 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='First Name'
                onChange={(e) => setFirstName(e.target.value)}
            />
            <Box sx={{ width: '100%'}}>
                <Typography>Last Name</Typography>
            </Box>
            <TextField 
                sx={{ width: '100%', pb: 2 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='Last Name'
                onChange={(e) => setLastName(e.target.value)}
            />
            <Box sx={{ width: '100%'}}>
                <Typography>Create Password</Typography>
            </Box>
            
            <TextField 
                sx={{ width: '100%', pb: 2 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='Create Password'
                type="password"
                onChange={(e) => setUpdatedPassword1(e.target.value)}
            />
            <Box sx={{ width: '100%'}}>
                <Typography>Confirm Password</Typography>
            </Box>
            <TextField 
                sx={{ width: '100%', pb: 4 }} 
                size='small' 
                id="myform"
                variant="outlined" 
                placeholder='Confirm Password'
                type="password"
                onChange={(e) => setUpdatedPassword2(e.target.value)}
            />
            <Button type="submit" onClick={updatePassword} id="login-button" variant='contained' sx={{ width: '100%', mb: 2 }}>create login</Button>
              </Box>
              :
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{mb: 3}}>This link has expired.</Typography>
                <Button variant='contained' href={'/'}>go home</Button>
               </Box> 
            }
           
            </Box>
            :
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{mb: 3}}>This link did not work.</Typography>
                <Button variant='contained' href={'/'}>go home</Button>
            </Box>
            }
        </Paper>
        <Modal
                open={open3}
            >
                <Box sx={styleModal}>
                    <Typography variant='h5' mb={4}>Account Creation Complete!</Typography>
                    <Button onClick={completeUser} sx={{width: '100%'}} variant='contained'>login</Button>
                </Box>
        </Modal>
      </Box>

      </ThemeProvider>
  )
}

export default Reset