import { Box, Breadcrumbs, Card, CardActionArea, CardContent, Link, Paper, Typography } from '@mui/material'
import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import { SupportAgent } from '@mui/icons-material';

function FAQ() {
  return (
    <Box     
        sx={{
            pl: {
                xs: '0px',
                lg: '60px',
            },
            pt: {
                xs: 6,
                lg: 0,
            },  
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            boxSizing: 'border-box'
        }}>
        <Box         
            sx={{ 
                width: {
                    xs: 300,
                    sm: 500,
                    md: 800,
                }, 
                mb: 10,
                mt: 5,
            }}>
            <Paper sx={{ boxSizing: 'border-box', p: 4, mb: 2}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Link underline="hover" color="inherit" href={`/learning`}>
                            Learning
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" 
                    sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}>
                        Video Library</Typography>
                </Box>
            </Box>
            <Box sx={{mb: 4}}>
                <Typography>
                    The Video Library is a great way to train staff when they do not need to complete any questions. 
                    Here you will have access to your videos that have been uploaded for you and our existing video library.
                </Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <Typography variant='h6'>Watch a Video Library Video:</Typography>
            </Box>
            <Box sx={{mb: 4}}>
                <Box sx={{mb: 0, display: 'flex'}}>
                    <Typography sx={{width: '35px'}}>1.</Typography>
                    <Typography sx={{mb: 1}}>From the dashboard click on the ‘Video Library’ Icon.</Typography>
                </Box>
                <Box sx={{mb: 0, display: 'flex'}}>
                    <Typography sx={{width: '35px'}}>2.</Typography>
                    <Typography sx={{mb: 1}}>Here you can see all the available videos.</Typography>
                </Box>
                <Box sx={{mb: 0, display: 'flex'}}>
                    <Typography sx={{width: '35px'}}>3.</Typography>
                    <Typography sx={{mb: 1}}>If you would like to watch any video click on the play icon.</Typography>
                </Box>
                <Box sx={{mb: 0, display: 'flex'}}>
                    <Typography sx={{width: '35px'}}>4.</Typography>
                    <Typography >To close the video click the x icon.</Typography>
                </Box>
            </Box>
            </Paper>

        <Paper sx={{ p: 4, display: 'flex', alignItems: 'center' }}>
                <Box>
                    <SupportAgent sx={{ fontSize: '50px', mr: 2}}/>
                </Box>
                <Box>
                    <Typography variant='h5' sx={{fontWeight: '600'}}>Still have questions?</Typography>
                    <Typography variant='body1' onClick={() => window.location = 'mailto:info@infinitedigital.co.nz?subject=Enquiry'} sx={{cursor: 'pointer'}}>Email us info@infinitedigital.co.nz</Typography>
                </Box>
            </Paper>
            </Box>
    </Box>
  )
}

export default FAQ