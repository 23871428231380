import React, { Fragment,useState, useEffect } from 'react';

import { Box, Container, Typography, Breadcrumbs, Link, Paper, InputLabel, Select, MenuItem, FormControl, TextField, Button, Modal, Tab, Tabs, IconButton, CircularProgress, Snackbar, Alert } from '@mui/material/'
import InputAdornment from '@mui/material/InputAdornment';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router';
import Logo from '../../Assets/Logo.png'
import {useParams} from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Close from '@mui/icons-material/Close';
import { HexColorInput } from "react-colorful";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import md5 from "md5";
import sha1 from "sha1";

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant='h6'>Admins</Typography>
        <GridToolbarQuickFilter sx={{ width: '90%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function Client() {
    const {client} = useParams();


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickColor = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseColor = () => {
      setAnchorEl(null);
    };
  
    const openColor = Boolean(anchorEl);
    const id = openColor ? 'simple-popover' : undefined;

    const [color, setColor] = useState("#aabbcc");

    const navigate = useNavigate();
    const [inducted, setInducted] = useState([]);
    const [inductions_total, setInductionsTotal] = useState([]);
    const [hours, setHours] = useState([]);
    const [inductedmonth, setInductedMonth] = useState([]);
    const [inductionsmonth, setInductionsMonth] = useState([]);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleOpen4 = () => setOpen3(true);
    const handleOpen5 = () => setOpen3(true);
    const handleClose1 = () => setOpen1(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const handleClose4 = () => setOpen4(false);
    const handleClose5 = () => setOpen5(false);
    const [users, setUsers] = useState([]);
    const [info, setInfo] = useState([]);
    const [clients, setClients] = useState([]);
    const params = useParams();
    const [user, setUser] = useState([]);
    const [category, setAge] = useState('');
        const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [username, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [updatefirst_name, setUpdateFirstName] = useState("");
    const [updatelast_name, setUpdateLastName] = useState("");
    const [updateusername, setUpdateEmail] = useState("");
    const [updatepassword, setUpdatePassword] = useState("");
    const [updatephone, setUpdatePhone] = useState("");
    const [updateadmin, setUpdateAdmin] = useState([]);
    const [clientupdate, setUpdateClient] = useState("");
    const [update_organisation_name, setUpdateOrgName] = useState("")
    const [update_contact_name, setUpdateContactName] = useState("");
    const [update_contact_email, setUpdateContactEmail] = useState("");
    const [update_contact_phone, setUpdateContactPhone] = useState("");
    const [update_hours, setUpdateHours] = useState("");
    const [updatepassword1, setUpdatedPassword1] = useState("");
    const [updatepassword2, setUpdatedPassword2] = useState("");

    // Creating Admin Error Alert
    const [open7, setOpen7] = React.useState(false);
    const handleOpen7 = () => setOpen7(true);
    const handleClose7 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    
    setOpen7(false);
    };

    // Update Admin Error Alert
    const [open8, setOpen8] = React.useState(false);
    const handleOpen8 = () => setOpen8(true);
    const handleClose8 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    
    setOpen8(false);
    };

    // Create Client Error Alert
    const [open9, setOpen9] = React.useState(false);
    const handleOpen9 = () => setOpen9(true);
    const handleClose9 = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen9(false);
    };

       // Create Client Error Alert
       const [open13, setOpen13] = React.useState(false);
       const handleOpen13 = () => setOpen13(true);
       const handleClose13 = (event, reason) => {
       if (reason === 'clickaway') {
           return;
       }
   
       setOpen13(false);
       };

    const openUpdateClient = async (e) => {
      setUpdateContactName(info[0].contact_name);
      setUpdateOrgName(info[0].organisation_name);
      setUpdateContactEmail(info[0].contact_email);
      setUpdateContactPhone(info[0].contact_phone);
      setUpdateHours(info[0].hours_saved);
      console.log("Set Client Info")
      setOpen1(true);
    }
    const updateClient = async (e) => {
      

      e.preventDefault();
      if(update_organisation_name === ""){
          setOpen9(true);
        }
    
        else if(update_contact_name === ""){
          setOpen9(true);
        }
  
        else if(update_contact_email === ""){
          setOpen9(true);
        }
  
        else if(update_contact_phone === ""){
          setOpen9(true);
        }

        else if(update_hours === ""){
          setOpen9(true);
        }

        else {
          try {

            const body = { update_organisation_name,update_contact_name,update_contact_email,update_contact_phone, update_hours };
            console.log(body)
            const response = await fetch(
              `${localStorage.getItem("url")}/api/updateClient/${info[0].id}`,
              {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
      
            window.location.reload();
          } catch (err) {
            console.error(err.message);
          }
        }

  };

    const createAdmin = async (e) => {
      e.preventDefault();
  
      if(first_name === ""){
        setOpen7(true);
      }


    else {
      try {
        const hash_password = md5(sha1(password));
        const body = { first_name, last_name, username, hash_password, phone };
        const response = await fetch(
          `${localStorage.getItem("url")}/api/registerAdminClient/${params.user}`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
        window.location.reload();
      } catch (err) {
        console.log(err.message);
      }
        }
    };
    
    const updateAdmin = async (e) => {


        e.preventDefault();
        if(updatefirst_name === ""){
            setOpen8(true);
          }
      
          else if(updatelast_name === ""){
            setOpen8(true);
          }
    
          else if(updateusername === ""){
            setOpen8(true);
          }
    
          else if(updatephone === ""){
            setOpen8(true);
          }

          else {
            try {

              const body = { updatefirst_name, updatelast_name, updateusername, updatephone };
              console.log(body)
              const response = await fetch(
                `${localStorage.getItem("url")}/api/updateAdmin/${updateadmin.id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              window.location.reload();
            } catch (err) {
              console.error(err.message);
            }
          }
        
    };

    const updatePassword = async (e) => {


      e.preventDefault();
         if (updatepassword1 == updatepassword2){
          try {
            const hash_password = md5(sha1(updatepassword1));
            const body = { hash_password };
            console.log(body)
            const response = await fetch(
              `${localStorage.getItem("url")}/api/update_user_password/${user.id}`,
              {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
      
            window.location.reload();
          } catch (err) {
            console.error(err.message);
          }
         } else {
          setOpen13(true);
         }

      

  };





    const handleChange = (event) => {
        setAge(event.target.value);
      };

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

      const getClients = async () => {
        try{
            console.log(params.user)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/clientusers/${params.user}`
            );
            const jsonData = await response.json();
         
       
            setUsers(jsonData);
          console.log(jsonData)
 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getInfo = async () => {
        try{
            console.log(params.user)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/clientinfo/${params.user}`
            );
            const jsonData = await response.json();
            setInfo(jsonData);
          console.log(jsonData)
 
            
           
            
        } catch (err){
            console.log(err.message);
        }

       
      };

      const getStaffInducted = async () =>{
        try{
          const response = await fetch(
              `${localStorage.getItem("url")}/api/staff_inducted_total/${params.user}`
          );
          const jsonData = await response.json();
       
          setInducted(jsonData);
          console.log(jsonData)
     
         
          
      } catch (err){
          console.log(err.message);
      }
      
    
     
     
    }; 
    
    const getInductions = async () =>{
      try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/inductions_total/${params.user}`
        );
        const jsonData = await response.json();
     
        setInductionsTotal(jsonData);
        console.log(jsonData)
    
       
        
    } catch (err){
        console.log(err.message);
    }
    
    
    
    
    }; 

    const getStaffInductedMonth = async () =>{
        try{
          const response = await fetch(
              `${localStorage.getItem("url")}/api/staff_inducted_month/${params.user}`
          );
          const jsonData = await response.json();
       
          setInductedMonth(jsonData);
          console.log(jsonData)
     
         
          
      } catch (err){
          console.log(err.message);
      }
      
    
     
     
    }; 
    
    const getInductionsMonth = async () =>{
      try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/inductions_month/${params.user}`
        );
        const jsonData = await response.json();
     
        setInductionsMonth(jsonData);
        console.log(jsonData)
    
       
        
    } catch (err){
        console.log(err.message);
    }
    
    
    
    
    }; 
    
    const columns = [
        { 
            field: 'name', 
            headerName: 'Business Name', 
            width: 250,
            renderCell: (cellValues) => {
                return (
                    <Box onClick={() => {navigate(`/infinite/${client}/${cellValues.value}`)}} sx={{ cursor: 'pointer'}}>
                        {cellValues.value}
                    </Box>
                        
                )
            }
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 100,
            renderCell: (cellValues) => {
                if ( cellValues.value == 3 ) {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                    </Box>
                )
                }

                else {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                    </Box>
                )
                }

            }
        },
        { field: 'username', headerName: 'Email', width: 300 },
        { field: 'contact_phone', headerName: 'Phone', width: 200 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Edit',
            width: 80,
            renderCell: (params) => {
                const onClickEdit = async () => {
                    setUpdateAdmin(params.row)
                    setUpdatePhone(params.row.phone);
                    setUpdateFirstName(params.row.firstname);
                    setUpdateLastName(params.row.lastname);
                    setUpdateEmail(params.row.username);
                  setOpen3(true);
                };
          
                return (
                  <>
                    <IconButton onClick={onClickEdit}>
                      <EditIcon />
                    </IconButton>
                  </>
                );
              },
          },
          {
            field: 'reset',
            type: 'actions',
            headerName: 'Reset',
            width: 80,
            renderCell: (params) => {
                const onClickEdit = async () => {
                  setUser(params.row)
                  setOpen8(true);
                };
          
                return (
                  <>
                    <IconButton onClick={onClickEdit}>
                      <RestartAltIcon />
                    </IconButton>
                  </>
                );
              },
          },

      ];



      
    useEffect(() => {
        getClients();
        getInfo();
        getStaffInducted();
        getInductions();
        getStaffInductedMonth();
        getInductionsMonth();
       }, []);

     
    

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px'}}>
        <Snackbar open={open7} autoHideDuration={6000} onClose={handleClose7} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose7}>
            Please fill out all fields to create an Admin.
            </Alert>
        </Snackbar>
        <Snackbar open={open9} autoHideDuration={6000} onClose={handleClose9} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose9}>
            Please fill out all fields to create an Client.
            </Alert>
        </Snackbar>
        <Snackbar open={open13} autoHideDuration={6000} onClose={handleClose13} sx={{ ml: 7 }}>
            <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose13}>
            Passwords do not match.
            </Alert>
        </Snackbar>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href="/infinite">
                    Dashboard
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>{info.length ? info[0].organisation_name : "loading.."}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant='contained' onClick={openUpdateClient} sx={{ mr: 2 }}>Edit</Button> 
                    <Button variant='contained' onClick={handleOpen2}>Create Admin</Button>
                </Box>

            </Box>
                
            <Typography variant='h6' mb={1}>Stats</Typography>
            <Grid container spacing={2} sx={{pb: 2}}>
                <Grid xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Staff Onboarded Total</Typography>
                                {inducted.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{inducted.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Inductions Total</Typography>
                                {inductions_total.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{inductions_total.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Staff Onboarded This Month</Typography>
                                {inducted.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{inductedmonth.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Inductions This Month</Typography>
                                {inductionsmonth.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{inductionsmonth.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
              </Grid>
              
              <Box sx={{ width: '100%', mb: 2}}>
                <Paper>
                        <DataGrid
                            sx={{ border: '0px'}}
                            rows={users}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                            components={{  Toolbar: QuickSearchToolbar }}
                        />
                    
                </Paper>
                
            </Box>
            <Box align='left' mb={10}>
                <Button variant="contained" sixe='lg' sx={{ width: '150px', marginRight: 2}}>
                    disable
                </Button>
            </Box>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Company Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Company Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Company Name'
                                defaultValue={info[0]?.organisation_name}
                                onChange={(e) => setUpdateOrgName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Hours Taken for Induction.</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Induction Hours'
                                defaultValue={info[0]?.hours_saved}
                                onChange={(e) => setUpdateHours(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Name'
                                defaultValue={info[0]?.contact_name}
                                onChange={(e) => setUpdateContactName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Contact Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Email'
                                defaultValue={info[0]?.contact_email}
                                onChange={(e) => setUpdateContactEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Contact Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue={info[0]?.contact_phone}
                                onChange={(e) => setUpdateContactPhone(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={updateClient} variant='contained'>Update</Button>
                        </Box>
                </Box>
            </Modal>
        <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Admin Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose2}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>First Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='First Name'
                                defaultValue=''
                                onSelect={(e) => setFirstName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Last Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Last Name'
                                defaultValue=''
                                onSelect={(e) => setLastName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue=''
                                onSelect={(e) => setEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue=''
                                onSelect={(e) => setPhone(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                defaultValue=''
                                onSelect={(e) => setPassword(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={createAdmin} variant='contained'>Create</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Admin Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose3}/>
                        </IconButton>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>First Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='First Name'
                                defaultValue={updateadmin.firstname}
                                onChange={(e) => setUpdateFirstName(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Last Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Last Name'
                                defaultValue={updateadmin.lastname}
                                onChange={(e) => setUpdateLastName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue={updateadmin.username}
                                onChange={(e) => setUpdateEmail(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                defaultValue={updateadmin.phone}
                                onChange={(e) => setUpdatePhone(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={(e) => updateAdmin(e)} variant='contained'>Update</Button>
                        </Box>
                </Box>
            </Modal>
            <Modal
                open={open8}
                onClose={handleClose8}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Password Reset</Typography>
                        <Close onClick={handleClose8} />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                onChange={(e) => setUpdatedPassword1(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Confirm Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Confirm Password'
                                onChange={(e) => setUpdatedPassword2(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={(e) => updatePassword(e)}>Update</Button>
                        </Box>
                </Box>
        </Modal>
    </Box>

  )
}

export default Client;