import React, { Fragment,useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert, Fab } from '@mui/material/'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Add, Close, DeleteOutline, HighlightOff } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <GridToolbarQuickFilter sx={{ width: '100%'}} />
      </Box>
    );
  }
  
  
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function Video() {
    const navigate = useNavigate();
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [row, setRows] = useState([]);
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inductioninfo, setInductionInfo] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleOpen5 = () => setOpen5(true);
  const handleOpen6 = () => setOpen6(true);
  const handleOpen8 = () => setOpen8(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose5 = () => setOpen5(false);
  const handleClose6 = () => setOpen6(false);
  const handleClose7 = () => setOpen7(false);
  const handleClose8 = () => setOpen8(false);



      // Creating Induction Error Alert
      const [open4, setOpen4] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
      const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
  const org = localStorage.getItem('org');

  const [newTitle, setnewTitle] = useState("");
  const [newVideoPath, setnewVideoPath] = useState("");
  const [newDescription, setnewDescription] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newVideoID, setNewVideoID] = useState("")
  
  const [induction, setInduction] = useState([]);
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateVideoID, setUpdateVideoID] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [updateCategory, setUpdateCategory] = useState("");
  const [updateVideo_id, setUpdateVideoId] = useState("")

  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");



  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 300,
        sm: 450,
      },
    boxSizing: 'border-box',
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const styleModal2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const top100Films = [
    { title: 'Example Video #1'},
    { title: 'Example Video #2'},
    { title: 'Example Video #3'},
    { title: 'Example Video #4'},
    { title: 'Example Video #5'},
  ]

  const categoryAuto = [
    { title: 'Orchard'},
    { title: 'Packhouse'},
    { title: 'Shop'},
    { title: 'Paddock'},
  ]

  const columns = [
    { 
        field: 'induction_title', 
        headerName: 'Title', 
        width: 275,
        renderCell: (cellValues) => {
            return (
                <Box   onClick={() => {getInduction(cellValues.id)}} sx={{ cursor: 'pointer'}}>
                    {cellValues.value}
                </Box>
                    
            )
        }
    },
    { 
        field: 'active', 
        headerName: 'Active', 
        width: 75,
        renderCell: (cellValues) => {
            if ( cellValues.value == 1 ) {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                </Box>
            )
            }

            else {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                </Box>
            )
            }

        }
    },
    { field: 'induction_description', headerName: 'Description', width: 450 },
    { field: 'category_name', headerName: 'Category', width: 150 },
    {   
    
        field: 'actions',
        type: 'actions',
        width: 50,
       
        renderCell: (params) => {
          const onClickEdit = async () => {
            setInduction(params.row);
            console.log(induction);
            setUpdateTitle(params.row.induction_title);
            setUpdateVideoID(params.row.video_id);
            setUpdateDescription(params.row.induction_description);
            setUpdateCategory(params.row.category_id);
            setUpdateVideoId(params.row.video_id);
            setOpen7(true);
          };
    
          return (
            <>
              <IconButton onClick={onClickEdit}>
                <EditIcon />
              </IconButton>
            </>
          );
        },
      },
      {   
    
        field: 'activate',
        type: 'actions',
        width: 125,
       
        renderCell: (params) => {
          const onClickActivate = async () => {
            setInductionInfo(params.row);
            setOpen5(true);
          };

          const onClickDeactivate = async () => {
            setInductionInfo(params.row);
            setOpen6(true);
            
          };
    
          if (params.row.active == 1) {
          return (
            <>
              <Button onClick={onClickDeactivate} sx={{color: 'GrayText'}}>Deactivate</Button>
            </>
          );
          }
          else {
            return (
                <>
                  <Button onClick={onClickActivate} sx={{color: 'GrayText'}}>Activate</Button>
                </>
              );
          }
        },
      },

  ];
  
  const selectedUpdate = async () => {
    console.log(induction)
    setSelectedVideo(videos.find(video => video.video_id === induction.video_id));
    console.log(selectedVideo);
    setSelectedCategory(categories.find(categories => categories.category_id === induction.category_id));
    console.log(selectedCategory);
    setOpen7(false);
    setOpen3(true);
  };

  const activateInduction = async (e) => {     
        try {

            const body = { };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/activateInduction/${inductioninfo.id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      };

      const deactivateInduction = async (e) => {     
        try {

            const body = { };
          console.log(body)
          const response = await fetch(
            `${localStorage.getItem("url")}/api/deactivateInduction/${inductioninfo.id}`,
            {
              method: "PUT",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
    
          window.location.reload();
        } catch (err) {
          console.error(err.message);
        }
      };


  const getOrgInductions = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/inductions/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setRows(jsonData);
        console.log(jsonData);
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  
  const getOrgVideos = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/videos/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setVideos(jsonData);
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  
  const getCategories = async () => {
    try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/categories2/${localStorage.getItem("org_id")}`
        );
        const jsonData = await response.json();
     
        setCategories(jsonData);
        console.log(categories)
   
       
        
    } catch (err){
        console.log(err.message);
    }
    

   
   
  };

  const addInduction = async (e) => {
    e.preventDefault();
    if(newTitle === ""){
        setOpen4(true);
      }
  
      else if(newVideoID === ""){
        setOpen4(true);
      }

      else if(newDescription === ""){
        setOpen4(true);
      }

      else if(newCategory === ""){
        setOpen4(true);
      }

    else {

            const org = localStorage.getItem("org_id")
            const body = { newTitle, newVideoID, newDescription, newCategory, org };
   
      const response = await fetch(
        `${localStorage.getItem("url")}/api/addInduction`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      window.location.reload();
    }

  };

  const updateInduction = async (e) => {
            e.preventDefault();
            console.log(updateCategory)
            if(updateTitle === ""){
              setOpen8(true);
            }
        
            else if(updateVideoID === ""){
              setOpen8(true);
            }
      
            else if(updateDescription === ""){
              setOpen8(true);
            }
      
            else if(updateCategory === ""){
              setOpen8(true);
            }
      
          else {
            try {
            const body = { updateTitle, updateVideoID, updateDescription, updateCategory};
            console.log(body);
            console.log(induction)
   
            const response = await fetch(
                `${localStorage.getItem("url")}/api/updateInduction/${induction.id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              window.location.reload();
            } catch (err) {
                console.error(err.message);
              }
          
            }
    };

  const getInduction = async (id) => {
     

    console.log(id);
    navigate(`${id}`)
   
   
  
}



  useEffect(() => {
    getOrgInductions();
    getCategories();
    getOrgVideos();
   
   
  
   }, []);



  return (
    <Box     
    sx={{
        pl: {
            xs: '0px',
            lg: '60px',
        },
        pt: {
            xs: 6,
            lg: 0,
        },  
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        boxSizing: 'border-box'
    }}>
        <Box         
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please fill out all fields to create an Induction.
              </Alert>
            </Snackbar>
            <Snackbar open={open8} autoHideDuration={6000} onClose={handleClose8} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose8}>
                Please fill out all fields to update an Induction.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" 
                    sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}>
                        Modules</Typography>
                </Box>
                <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex',
                    },
                }}
                >
                    Add
                </Button>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={handleOpen1} sx={{zIndex: 1}}>
                        <Add />
                    </Fab>
                </Box>
            </Box>
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                    <DataGrid
                    sx={{ border: '0px'}}
                    rows={row || {id: "loading"}}
                    columns={columns}
                    pageSize={10}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    autoHeight
                    components={{  Toolbar: QuickSearchToolbar }}
                    getRowId={(row) => row.id}
                />

            </Paper>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Induction Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                  <Box sx={{ mb: 1 }}>
                            <Typography>Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setnewTitle(e.target.value)}
                            />
                        </Box>
                    <Box sx={{ mb: 1 }}>
                        <Typography>Video</Typography>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setNewVideoID(b.video_id)}
                                options={videos}
                                getOptionLabel={(video) => video.video_title }
                                
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.video_id}
                                    placeholder='Video'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography>Instructions</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                           
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Instructions'
                                multiline
                                onChange={(e) => setnewDescription(e.target.value) }
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Category</Typography>
                            <Autocomplete
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setNewCategory(b.category_id)}
                                options={categories}
                                getOptionLabel={(category) => category.category_name }
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.category_id}
                                    placeholder='Category'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={addInduction}>Create</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Induction Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose3}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                            <Typography>Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Title'
                                onChange={(e) => setUpdateTitle(e.target.value)}
                                name={induction.induction_title}
                                defaultValue={induction.induction_title}
                            />
                        </Box>
                    <Box sx={{ mb: 1 }}>
                        <Typography>Video</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                defaultValue={selectedVideo}
                                options={videos}
                                getOptionLabel={(video) => video.video_title }
                                onChange={(a, b) => setUpdateVideoID(b.video_id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Video'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography>Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                           
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Breif description'
                                multiline
                                onChange={(e) => setUpdateDescription(e.target.value) }
                                defaultValue={induction.induction_description}
                                
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Category</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={categories}
                                defaultValue={selectedCategory}
                                getOptionLabel={(category) => category.category_name }
                                onChange={(a, b) => setUpdateCategory(b.category_id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.category_id}
                                    placeholder='Category'
                                    onChange={(e) => setUpdateCategory(e.target.value)}
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={updateInduction}>save</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open5}
                onClose={handleClose5}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                            <Typography variant='h6'>Are you sure you want to activate this induction?</Typography>
                        </Box>
                        <Button onClick={activateInduction}>Yes</Button>
                        <Button onClick={handleClose5}>No</Button>
                        
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open6}
                onClose={handleClose6}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                            <Typography variant='h6'>Are you sure you want to deactivate this induction?</Typography>
                        </Box>
                        <Button onClick={deactivateInduction}>Yes</Button>
                        <Button onClick={handleClose6}>No</Button>
                        
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open7}
                onClose={handleClose7}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                            <Typography variant='h6'>Are you sure you want to edit this induction?</Typography>
                        </Box>
                        <Button onClick={selectedUpdate}>Yes</Button>
                        <Button onClick={handleClose7}>No</Button>
                        
                    </Box>
                </Box>
            </Modal>
    </Box>
  )
}

export default Video
