import React, { Fragment,useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography,CircularProgress,createTheme, ThemeProvider,  Button, Modal, Select,Snackbar, Alert, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, Autocomplete, IconButton, getTableSortLabelUtilityClass, Backdrop } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../../Assets/thumbnail.jpg'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Close from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { ConstructionOutlined, LocalSeeOutlined, WindowSharp } from '@mui/icons-material';
import { exit } from 'process';
import Moment from 'moment';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { groupBy, isNull } from "lodash";
import { useNavigate } from 'react-router';
import SignaturePad from "react-signature-canvas";
import './group.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Indcution1() {
  const sigCanvas = useRef({});
  
  /* a function that uses the canvas ref to clear the canvas 
  via a method given by react-signature-canvas */
  const clear = () => sigCanvas.current.clear();
  const sleep = ms => new Promise(r => setTimeout(r, ms));
    const navigate = useNavigate()
    const [groupTotal, setGroupTotal] = useState("");
    const [inductions, setInductions] = useState([])
    const [usersWatching, setUsersWatching] = useState([])
    const [usersStillWatching, setUsersStillWatching] = useState([])
    const [currentUser, setCurrentUser] = useState("")
    const [induction_group_id, setInductionGroupID] = useState("")
    const [induction_id, setInductionID] = useState(0)
    const [categoryCount, setCategoryCount] = useState(0)
    const [arr1, setArr1] = useState([]);
    const [arr2, setArr2] = useState([]);
    const [open3, setOpen3] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [finished, setFinished] = React.useState(false);
    // const handleChange = (event) => {
    //     inductions.forEach((induction) => {
    //        selectedValue.push(event.target.value);
    //     })
      
    // };
    const theme = createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#66BC44',
            contrastText: 'rgba(255,255,255,0.87)',
          },
          secondary: {
            main: '#f50057',
          },
        },
      });

      const handleOpen3 = () => setOpen4(true);
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
    const [open4, setOpen4] = React.useState(false);
    const [open10, setOpen10] = React.useState(false);
    const [open20, setOpen20] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);

      const handleOpen4 = () => setOpen4(true);
    const handleOpen5 = () => setOpen5(true);
    const handleOpen6 = () => setOpen6(true);
    const handleOpen7 = () => setOpen7(true);
    const handleOpen8 = () => setOpen8(true);
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
  
    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          setOpen10(false)
          return;
        }
    
        setOpen4(false);
      };

      const handleClose20 = (event, reason) => {
        if (reason === 'clickaway') {
          setOpen20(false)
          return;
        }
    
        setOpen20(false);
      };

        const handleClose5 = (event, reason) => {
        if (reason === 'clickaway') {
          setOpen10(false)
          return;
        }
    
        setOpen5(false);
      };

      const handleClose6 = (event, reason) => {
        if (reason === 'clickaway') {
          setOpen10(false)
          return;
        }
    
        setOpen6(false);
      };
      const handleClose7 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen7(false);
      };
      const handleClose8 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen8(false);
      };

      const styleModal = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        width: {
            xs: 300,
            sm: 450,
          },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

    

     

      const getInductionUsers = async () =>{
        try{


            const response1 = await fetch(
                `${localStorage.getItem("url")}/api/inductions/${localStorage.getItem("org_id")}/${localStorage.getItem("induction_group_id")}`

            );

            
            const jsonData1 = await response1.json();
           
                console.log(jsonData1)
            var induction_id = jsonData1[0].induction_id
            const result = groupBy(jsonData1, 'induction_id');
          
           
        

     
          const response = await fetch(
              `${localStorage.getItem("url")}/api/group_users/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}/${induction_id}`
          );
          const jsonData = await response.json();
            
       
         

       
         
      
  
            var abort = false;
          if(jsonData.length == 0){
           

            const array = Object.entries(result).map(([key]) => key)
            console.log(array)

            for(let i = 0; i < array.length  && !abort; i++){
               
                console.log(i)
                for(let a = 0; a < array.slice(i+1).length && !abort; a++){
                    console.log(a)
                    console.log(i)
                    induction_id = array.slice(i+1)
                 
                    console.log(array.slice(i+1))
                 
                    const response2 =  await fetch(
                        `${localStorage.getItem("url")}/api/induction/${localStorage.getItem("org_id")}/${localStorage.getItem("induction_group_id")}/${induction_id}`
        
                    );
                    const jsonData1 = await response2.json(); 
                        

                    if(jsonData1.length){
                        
                        
                      
                        const response = await fetch(
                            `${localStorage.getItem("url")}/api/group_users/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}/${induction_id}`
                        );
                        const jsonData = await response.json();
                            
                        if(jsonData.length){
                            
                            
                            localStorage.setItem("induction_group_id", jsonData[0].category_id)
                          
                            setInductionGroupID(jsonData.category_id);
                            setInductionID(jsonData[0].induction_id)
                          
                            setInductions(jsonData1)
                            jsonData1.map((result) => {
             
               
             

                              if(!questions.includes(result.question_text)){
                               questions.push(result.question_text)
                               answers.push(result.correct_answer)
                              }else{
                 
                              }
                              
                              
                             })
                            console.log(jsonData1)
                            setUsersWatching(jsonData);
                            abort = true;
                           
                           return false
                         
                        }else if(1 == array.slice(i+1).length ){
                            console.log("hi")
                            setFinished(true)
                            setOpen3(true)
                        }
                        else{
                            
                        }
                      
                    }else{
                        
                        
                    }
                    
              
                }
         

                
                
            }
          
            
            

          }else{
            localStorage.setItem("induction_group_id", jsonData[0].category_id)
            setInductionGroupID(jsonData[0].category_id);

          
            Object.entries(result)[0][1].map((result) => {
             
               
             

             if(!questions.includes(result.question_text)){
              questions.push(result.question_text)
              answers.push(result.correct_answer)
             }else{

             }
             
             
            })
          
            console.log(jsonData)
            setInductions(Object.entries(result)[0][1])
            setUsersWatching(jsonData);
            setInductionID(jsonData[0].induction_id)
            
            
          }
          
         



       
            
          
           
          
     
          
         
          
      } catch (err){
          console.log(err.message);
      }
      
    }; 
    
       
    const getCategoryCount = async () => {
        try{
            
            const response = await fetch(
                `${localStorage.getItem("url")}/api/category/${localStorage.getItem("org_id")}/${localStorage.getItem("induction_group_id")}`

            );
            const jsonData = await response.json();
          
            setCategoryCount(jsonData.length);
          
            console.log(jsonData)
       
           
            
        } catch (err){
            console.log(err.message);
        }
        
    
      }
 
    


     
      


      const handleNext = async () => {
        try{
            
            console.log(induction_id)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/group_users/${localStorage.getItem("group_id")}/${localStorage.getItem("org_id")}/${induction_id}`
            );
            const jsonData = await response.json();
              
            if(jsonData.length == 0){
                window.location.reload()
            }else{
                setOpen8(true)
            }
           
       
           
            
        } catch (err){
            console.log(err.message);
        }
        
        
      }

      const handlePrevious = () => {

        console.log(groupTotal)
        var localCount = parseInt(localStorage.getItem("users_inducted"));
        console.log(localCount)
        var fill = 0;
    
        console.log(fill)
        console.log(localCount)
        if(localCount == 0){
            setOpen7(true)
        }else{
            localCount -= (parseInt(groupTotal) + 1)
            localStorage.setItem("users_inducted", localCount )
            localStorage.removeItem("usersStillWatching")
            window.location.reload();
        }
        
      }


      const handleSubmit = () => {
       const signature = sigCanvas.current.isEmpty()
       console.log(signature)
       var error = "";
       var correct_answers_array = []
        if(currentUser === ""){
         
            setOpen4(true);
        }
        else if (signature === true){
          setOpen20(true);
        }
        
        else{
         
            const id = inductions[0].induction_id
            var correct_answers_length = inductions.length
     
            inductions.forEach((induction) => {
               
                const arr = induction.answer_text.split(",")
                const r = induction.question_id
              
   
                 

                    const answer = document.getElementsByName(induction.question_id);
                    console.log(document.getElementsByName(induction.question_id))
                    console.log(answer.length)
                 
                for(var i = 0; i < answer.length; i++){
                    
                    
                     if(answer[i].value == induction.correct_answer && answer[i].checked){
                           
                            correct_answers_array.push(answer[i].value);
    
                           
                        }       
                        else{
                         
                            
                        }  
                    }   
                       
                   
                })

                    
                if(correct_answers_array.length === correct_answers_length){
                  setOpen10(true)
                  completeInduction(currentUser);
                  
              }else{
                  setOpen5(true);
              }
                
                
            }
           
           

        };

        const removeUser = (user_id) => {
            console.log(user_id)
            var usersWatchingNext = []
            console.log(usersWatching)
                   usersWatching.map((user) => {
                    console.log(user)
                    if(user.user_id !== user_id){
                        console.log(user.id)
                        usersWatchingNext.push(user.user_id)
                    }
                   })

                   localStorage.setItem("usersStillWatching", usersWatchingNext)

        }

      
   


      const completeInduction = async (usersname) => {
        usersWatching.forEach(async (user) => {
            if(user.name === usersname){
                console.log(user)
                const user_id = user.user_id
                console.log(user_id)
                const induction = inductions[0].induction_id   
                const current = new Date();
                const sign = sigCanvas.current.getTrimmedCanvas().toDataURL();
                const datetime = Moment(current).format('YYYY-MM-DD hh:mm:ss');
                const group_id = localStorage.getItem("group_id")
                const org = localStorage.getItem("org_id")
                const q = questions;
                const a = answers;
                const body = { user_id,induction,datetime, org, group_id,sign,q,a };
             
                console.log(Moment(current).format('YYYY-MM-DD'))

                const response =  fetch(
                    `${localStorage.getItem("url")}/api/completeInduction`,
                    {
                      method: "POST",
                      headers: { "content-type": "application/json" },
                      body: JSON.stringify(body),
                    }
                  )

                  await sleep(2000)
                  window.location.reload()
                
                 
             
                
                   
                 }
                  
              
                })

            
        
      }

      const finishInduction = async (req, res) => {
        try{
            
            const response = await fetch(
                `${localStorage.getItem("url")}/api/groupFinished/${localStorage.getItem("org_id")}/${localStorage.getItem("group_id")}`

            );
            const jsonData = await response.json();
            console.log(jsonData)
            if(jsonData[0].total == groupTotal){
                console.log("HI")
                localStorage.removeItem("users_inducted");
        localStorage.removeItem("usersStillWatching")
        localStorage.removeItem("users_watching")
       
       
        setOpen3(true)
            }else{
                setOpen8(true)
            }
           
       
           
            
        } catch (err){
            console.log(err.message);
        }
        
        
       
       
      
    }

    const handleFinish = async (req, res) => {
       
               
            
                const current = new Date();
               
                const datetime = Moment(current).format('YYYY-MM-DD hh:mm:ss');
                const group_id = localStorage.getItem("group_id")
                const org = localStorage.getItem("org_id")
           
                const body = { datetime, org, group_id };
              
                console.log(Moment(current).format('YYYY-MM-DD'))

                const response =  fetch(
                    `${localStorage.getItem("url")}/api/updateInductionGroup`,
                    {
                      method: "POST",
                      headers: { "content-type": "application/json" },
                      body: JSON.stringify(body),
                    }
                  )
                  localStorage.removeItem("induction_group_id")
                  localStorage.removeItem("org_id");
                  localStorage.removeItem("group_id");
        navigate("/")
    }

    const getGroupTotal = async ()  => {
        try{
            
            const response = await fetch(
                `${localStorage.getItem("url")}/api/groupTotal/${localStorage.getItem("org_id")}/${localStorage.getItem("group_id")}`

            );
            const jsonData = await response.json();
          
            setGroupTotal(jsonData[0].count);
           
       
            await new Promise(r => setTimeout(r, 2000));
            
        } catch (err){
            console.log(err.message);
        }
    }

   



      useEffect(() => {
        getInductionUsers();
     
       //getIUsersWatching();
       getCategoryCount();

       getGroupTotal()
  
      
       }, []);

       



 if(inductions.length || finished == 1){
    return (
        <ThemeProvider theme={theme}>

       
        <Box 
        sx={{
            pl: {
                xs: '0px',
                lg: '60px',
            },
            pt: {
                xs: 6,
                lg: 0,
            },  
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            boxSizing: 'border-box'
        }}>
        
                <Box 
                sx={{ 
                    width: {
                        xs: 300,
                        sm: 500,
                        md: 800,
                        lg: 1000,
                        xl: 1200,
                    }, 
                    mb: 10
                    }}>
                <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
                    <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                        Please select people to start an Induction.
                    </Alert>
                </Snackbar>
                <Snackbar open={open20} autoHideDuration={6000} onClose={handleClose20} sx={{ ml: 7 }}>
                    <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose20}>
                        Please sign in the signature box.
                    </Alert>
                </Snackbar>
                <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open10}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
                <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                    </Breadcrumbs>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                        <Typography  variant="h4" sx={{ fontWeight: '600'}}>Group Induction</Typography>
                    </Box>
                    <Paper sx={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column' , p: 4}}>
                        <Typography variant='h5' sx={{mb: 2, fontWeight: '600' }}>{inductions.length ? inductions[0].induction_title : "loading"}</Typography>
                      
                        <Box sx={{mb: 2 }}>
                            <Typography >User</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: {xs: '100%', md: '60%', xl: '50%'}, mb: 3}} 
                                disableClearable
                                options={usersWatching.map((user) => user.name)}
                              
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onSelect={(e) => setCurrentUser(e.target.value)}
                                    placeholder='Who is answering the questions?'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                       
                             {inductions.map((induction) => 
                             <FormControl sx={{mb: 3}}>
                            <FormLabel sx={{ color: 'black'}} id="demo-radio-buttons-group-label">{induction.question_text}</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                // onChange={handleChange}
                             
                            >
                              

                                    {induction.answer_text.split(',').map(function (answer , index){
                                        return (
                                            <FormControlLabel name={induction.question_id} value={answer} control={<Radio />} label={answer}/>
                                        )
                                    })}

                                
                                
                            </RadioGroup>
                        </FormControl>)}
                        <Typography >Signed by the employee:</Typography>
                        <Box sx={{ backgroundColor: '#FFFF'}}>
                                <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                    className: "signatureCanvas"
                                    }}
                                />
                            <IconButton color="primary" onClick={clear} sx={{pb: 4}}>
                                  <CloseRoundedIcon />
                                </IconButton>

                            </Box>
   
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Button type="submit"  onClick={handleSubmit} sx={{mb : 2}} variant='contained' >Submit</Button>
                        </Box>
                        <Box>

                        </Box>
                       
                        <Box>

                        </Box>
                    </Paper>
                    <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                One or more answers are incorrect.
              </Alert>
            </Snackbar>
            <Snackbar open={open6} autoHideDuration={6000} onClose={handleClose6} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose6}>
                There are no more Inductions for this category.
              </Alert>
            </Snackbar>
            <Snackbar open={open7} autoHideDuration={6000} onClose={handleClose7} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose7}>
                This is the first induction of the group.
              </Alert>
            </Snackbar>
            <Snackbar open={open8} autoHideDuration={6000} onClose={handleClose8} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose8}>
                Not all users have been inducted.
              </Alert>
            </Snackbar>
            
                </Box>
                <Modal
                open={open3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Typography variant='h5' mb={1}>Induction Completed</Typography>
                    <Typography variant='body1' color={'GrayText'} mb={3}>All Users have been inducted.</Typography>
                    <Button type="submit" onClick={handleFinish} sx={{width: '100%'}} variant='contained'>Finish Induction</Button>
                </Box>
        </Modal>
            </Box>
            </ThemeProvider>
          )
 } else{
    return(
        <Box>
          <CircularProgress color="inherit" />
        </Box>
    )
 }
}

export default Indcution1
