import React, { Fragment,useState, useEffect } from 'react';
import { Box, Container, Typography, InputLabel, Select, MenuItem, FormControl, TextField, Button, Modal, Card, CardActionArea, CardContent, Autocomplete, OutlinedInput, Snackbar, Alert, Fab, IconButton, CircularProgress } from '@mui/material/'
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridRowId, GridColumnApi, GridCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from 'react-router';
import Close from '@mui/icons-material/Close';
import Moment from 'moment';

import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Unstable_Grid2';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Delete, RestartAlt, SelectAllTwoTone, ThreeSixtyTwoTone, WindowSharp } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import md5 from "md5";
import sha1 from "sha1";
import emailjs, { send } from '@emailjs/browser';

function addHours(date, hours) {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);

  return date;
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 2,
        boxSizing: 'border-box',
        pb: 0,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant='h6'>All Users</Typography>
      <GridToolbarQuickFilter 
      sx={{ 
        width: {
          xs: '60%',
          sm: '75%',
          md: '80%',
          lg: '90%'
        },
        }} />
    </Box>
  );
}



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'All',
  'Orchard',
  'Packhouse',
  'Paddock',
];

function Users() {

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
    const [color, setColor] = useState("#aabbcc");
    const [checkboxSelection, setCheckboxSelection] = React.useState(true);
    const navigate = useNavigate();
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);

    // Start Inductioin Alert
    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen4(false);
    };

    // Category Alert
    const [open5, setOpen5] = React.useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen5(false);
    };

    // Creating User Error Alert
    const [open6, setOpen6] = React.useState(false);
    const handleOpen6 = () => setOpen6(true);
    const handleClose6 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen6(false);
    };
        
     // Creating Incorrect Password Alert
     const [open9, setOpen9] = React.useState(false);
     const handleOpen9 = () => setOpen9(true);
     const handleClose9 = (event, reason) => {
       if (reason === 'clickaway') {
         return;
       }
   
       setOpen6(false);
     };

    const handleOpen1 = () => setOpen1(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleClose1 = () => setOpen1(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const handleClose8 = () => setOpen8(false);
    const [row, setRows] = useState([]);
    const [modalInfo, setModalInfo] = useState([]);
    const [inducted, setInducted] = useState([]);
    const [hours, setHours] = useState([]);
    const [user, setUser] = useState([]);
    const [updatedName, setUpdatedName] = useState("");
    const [updatedUsername, setUpdatedUserName] = useState("");
    const [updateContactPhone, setUpdatedContactPhone] = useState("")
    const [firstname, setnewFirstName] = useState("");
    const [lastname, setnewLastName] = useState("");
    const [newUsername, setnewUserName] = useState("");
    const [category, setNewCategory] = useState("");
    const [newContactPhone, setnewContactPhone] = useState("")
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [password, setPassword] = useState("");
    const [updatepassword1, setUpdatedPassword1] = useState("");
    const [updatepassword2, setUpdatedPassword2] = useState("");
    const [token, setToken] = useState("")
    const [token_date, setDate] = useState("")

    const [age, setAge] = React.useState('');

    const sendEmail = (e) => {

      };

   const creatUser = async (e) => {
    e.preventDefault();

    if(newUsername === ""){
      setOpen6(true);
    }

    else if(category === ""){
      setOpen6(true);
    }

    else {
      const token = Math.floor(100000 + Math.random() * 900000);
      const token_date_og = addHours(new Date(), 1);
      const hash_password = md5(sha1(password));
      console.log(token)
      console.log(token_date_og)
      const form = {"token" : token, "email" : newUsername,}
      emailjs.send("service_zg9udxl","template_a96m8oi", form, "ujgeGjrhqt-YsyTep")
      const org = localStorage.getItem("org_id")
      const current = new Date();
      const date = Moment(current).format('YYYY-MM-DD');
      const token_date = Moment(token_date_og).format('YYYY-MM-DD hh:mm:ss');
      const body = { firstname, lastname, hash_password, newUsername,  newContactPhone, org, date, category, token, token_date };
   
      const response = await fetch(
        `${localStorage.getItem("url")}/api/addUser`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      getOrgUsers();
      setOpen2(false);

    }
  };


const hideEdit = async (id) => {
    const edits = document.getElementsByClassName("Edit")
    for(var i= 0; i<edits.length; i++){
        
        if(id != edits[i].id){
            i++;
             
         }else{
             if(edits[i].style.display === "none") {
                 edits[i].style.display = "block"
             }else{
         edits[i].style.display = "none"
             }
         }
    }
    
       
   
    
    
}

  const getStaffInducted = async () =>{
    try{
      const response = await fetch(
          `${localStorage.getItem("url")}/api/staff_inducted_total/${localStorage.getItem("org_id")}`
      );
      const jsonData = await response.json();
   
      setInducted(jsonData);
      console.log(jsonData)
      console.log(inducted.length)
 
     
      
  } catch (err){
      console.log(err.message);
  }
  

 
 
}; 

const getHoursSaved = async () =>{
  try{
    const response = await fetch(
        `${localStorage.getItem("url")}/api/hours_saved/${localStorage.getItem("org_id")}`
    );
    const jsonData = await response.json();
 
    setHours(jsonData);
    console.log(jsonData)

   
    
} catch (err){
    console.log(err.message);
}




}; 

    const updateUser = async (e) => {


        e.preventDefault();
           
            try {

                
                const fullname_split = updatedName.split(" ");
                const firstname = fullname_split.slice(0,fullname_split.length -1);
                const name = firstname.join(" ")
                const lastname = fullname_split[fullname_split.length -1];
              const body = { name,lastname,updatedUsername,updateContactPhone };
              console.log(body)
              const response = await fetch(
                `${localStorage.getItem("url")}/api/updateUser/${user.id}`,
                {
                  method: "PUT",
                  headers: { "Content-type": "application/json" },
                  body: JSON.stringify(body),
                }
              );
        
              window.location.reload();
            } catch (err) {
              console.error(err.message);
            }
        

    };

    const updatePassword = async (e) => {


      e.preventDefault();
         if (updatepassword1 == updatepassword2){
          try {
            const hash_password = md5(sha1(updatepassword1));
            const body = { hash_password };
            console.log(body)
            const response = await fetch(
              `${localStorage.getItem("url")}/api/update_user_password/${user.id}`,
              {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
      
            window.location.reload();
          } catch (err) {
            console.error(err.message);
          }
         } else {
          setOpen9(true);
         }

      

  };

    const handleChange = (event) => {
      setAge(event.target.value);
    };


    const handleRowClick = (params) => {
      
        setUser(params.row)
        setUpdatedContactPhone(params.row.contact_phone);
        setUpdatedName(params.row.name);
        setUpdatedUserName(params.row.username);
       
        console.log(params.row)
      };

    const rowEvents = {
        onClick: (row) => {
            console.log(row)
        }
    }
  
    const test = (id) => {
        handleOpen3();
        test(id);
    }

  
         
 

    const getOrgUsers = async () => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/users/${localStorage.getItem("org_id")}`
            );
            const jsonData = await response.json();
         
            setRows(jsonData);
            console.log(jsonData)
       
           
            
        } catch (err){
            console.log(err.message);
        }
        

       
       
      };

      const getUser = async (id) => {
     

        console.log(id);
        navigate(`${id}`)
       
       
      
    }
    

    
  

    const styleModal = {
        position: 'absolute',
        boxSizing: 'border-box',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
          xs: 300,
          sm: 450,
        },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

    const columns = [
      { 
        field: 'percent', 
        headerName: 'Status', 
        width: 70,
        renderCell: (cellValues) => {
          console.log(cellValues)
          console.log(cellValues.value)
            if ( cellValues.value.length > 3 ) {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircleIcon sx={{ width: '10px', color: 'green', pr: 1}}/>
                </Box>
            )
            }

            else if ( cellValues.value.length > 2 ) {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircleIcon sx={{ width: '10px', color: 'orange', pr: 1}}/>
                </Box>
            )
            }

            else {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircleIcon sx={{ width: '10px', color: 'red', pr: 1}}/>
                </Box>
            )
            }

        }
    },
        { 
            field: 'name', 
            headerName: 'Full Name', 
            width: 350,
            renderCell: (cellValues) => {
              console.log(cellValues.value)
                return (
                    <Box   onClick={() => {getUser(cellValues.id)}} sx={{ cursor: 'pointer'}}>
                        {cellValues.value}
                    </Box>
                        
                )
            }
        },
        { field: 'username', headerName: 'Email / Username', width: 350 },
        

        {   
    
            field: 'actions',
            type: 'actions',
            width: 50,
           
            renderCell: (params) => {
              const onClickEdit = async () => {
                setUser(params.row)
                setUpdatedContactPhone(params.row.contact_phone);
                setUpdatedName(params.row.name);
                setUpdatedUserName(params.row.username);
                setOpen3(true);
              };
        
              return (
                <>
                  <IconButton onClick={onClickEdit}>
                    <EditIcon />
                  </IconButton>
                </>
              );
            },
          },
          {   
    
            field: 'reset',
            type: 'actions',
            width: 50,
           
            renderCell: (params) => {
              const onClickEdit = async () => {
                setUser(params.row)
                setOpen8(true);
              };
        
              return (
                <>
                  <IconButton onClick={onClickEdit}>
                    <RestartAlt />
                  </IconButton>
                </>
              );
            },
          },

      ];

      const [personName, setPersonName] = React.useState([]);
    
      const handleName = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      const getCategories = async () => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/categories/${localStorage.getItem("org_id")}`
            );
            const jsonData = await response.json();
              console.log(jsonData)
            setCategories(jsonData);
       
           
            
        } catch (err){
            console.log(err.message);
        }
        
    
       
       
      };
     

      const StartInduction = async (category) => {
        var category_id = 0; 
          if(selectedCategory === ""){
            setOpen5(true);
        }else{
    
          categories.forEach((category) => {
            if(category.category_name === selectedCategory){
              category_id = category.category_id
            }
          })
      
          localStorage.setItem("category", category_id) 
          window.location = `/watch/${category_id}`;
          console.log(selectedCategory)
        }
     


      }

      useEffect(() => {
        getOrgUsers();
        getCategories();
        getStaffInducted();
        getHoursSaved();
       
      
       }, []);


  return (
    <Box     
    sx={{
      pl: {
          xs: '0px',
          lg: '60px',
      },
      pt: {
          xs: 6,
          lg: 0,
      },  
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      boxSizing: 'border-box'
  }}>
        <Box             
        sx={{ 
            width: {
                xs: 300,
                sm: 500,
                md: 800,
                lg: 1000,
                xl: 1200,
            }, 
            mb: 10
            }}>
            <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                Please select people to start an Induction.
              </Alert>
            </Snackbar>
            <Snackbar open={open5} autoHideDuration={6000} onClose={handleClose5} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose5}>
                You must select a category before inducting users.
              </Alert>
            </Snackbar>
            <Snackbar open={open6} autoHideDuration={6000} onClose={handleClose6} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose6}>
                Enter all User's information.
              </Alert>
            </Snackbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2, marginTop: 5, }}>
                <Typography  variant="h4"
                  sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}
                    >Dashboard</Typography>
                <Box 
                sx={{ 
                  display: {
                    xs: 'none',
                    md: 'flex',
                  }, 
                  alignItems: 'center' 
                  }}>
                    <Button variant='contained' onClick={handleOpen2}>CREATE USER</Button>
                </Box>
                <Box sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                    pt: 2,
                }}>
                    <Fab size="small" color="primary" aria-label="add" onClick={handleOpen2} sx={{zIndex: 1}}>
                        <Add />
                    </Fab>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{pb: 2}}>
                <Grid xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Employees Onboarded</Typography>
                                {inducted.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{inducted.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={4}>
                    <Card>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant='body1'>Hours Saved</Typography>
                                {hours.length 
                                    ?
                                      <Typography variant='h4' sx={{ fontWeight: '600'}}>{hours[0].hours_saved*inducted.length}</Typography>
                                    :
                                    <Typography variant='h4' sx={{ fontWeight: '600'}}>0</Typography>
                                }
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
              </Grid>
            <Box sx={{ width: '100%', mb: 2}}>
                <Paper>
                    <DataGrid
                        checkboxSelection={checkboxSelection}
                        sx={{ border: '0px'}}
                        rows={row || {id: "loading"}}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        columns={columns}
                        onRowClick={handleRowClick} 
                        
                        experimentalFeatures={{ newEditingApi: true }}
                        autoHeight
                        components={{  Toolbar: QuickSearchToolbar }}
                    
                    />
                </Paper>
              </Box>

            
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Induction Category</Typography>
                        <Close onClick={handleClose1}/>
                    </Box>
                       <Box sx={{ pb: 3}}>
                        <FormControl sx={{ m: 0, width: '100%', mt: 0, backgroundColor: 'white' }}>
                        <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={categories.map((option) => option.category_name)}
                              
                                
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                  
                        
                                    placeholder='Category'
                                    onSelect={(e) => setSelectedCategory(e.target.value)}
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </FormControl>
                       </Box>
                        <Box align='right' >
                            <Button onClick={StartInduction} variant='contained'>START INDUCTION</Button>
                        </Box>
                </Box>
        </Modal>
        <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>User Details</Typography>
                        <Close onClick={handleClose2}/>
                    </Box>
                      <Box align="left" mb={1}>
                            <Typography>First Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='First Name'
                                defaultValue=''
                                onChange={(e) => setnewFirstName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Last Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Last Name'
                                defaultValue=''
                                onChange={(e) => setnewLastName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue=''
                                onChange={(e) => setnewUserName(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                defaultValue=''
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ mb: 4 }}>
                        <Typography>Category</Typography>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setNewCategory(b.category_id)}
                                options={categories}
                                getOptionLabel={(category) => category.category_name }
                                
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.category_id}
                                    placeholder='Category'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box align='right'>
                            <Button onClick={creatUser} variant='contained'>Create</Button>
                        </Box>
                </Box>
        </Modal>
        <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>User Details</Typography>
                        <Close onClick={handleClose3} />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Full Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Full Name'
                               name={user.name}
                                defaultValue={user.name}
                                onChange={(e) => setUpdatedName(e.target.value)}
                          
                        />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                               name={user.username}
                                variant="outlined" 
                                placeholder='Email'
                              
                                defaultValue={user.username}
                                onChange={(e) => setUpdatedUserName(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={(e) => updateUser(e)}>Update</Button>
                        </Box>
                </Box>
        </Modal>
        <Modal
                open={open8}
                onClose={handleClose8}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Password Reset</Typography>
                        <Close onClick={handleClose8} />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Password'
                                onChange={(e) => setUpdatedPassword1(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Confirm Password</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Confirm Password'
                                onChange={(e) => setUpdatedPassword2(e.target.value)}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={(e) => updatePassword(e)}>Update</Button>
                        </Box>
                </Box>
        </Modal>
    </Box>

  )
}

export default Users