import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete } from '@mui/material/'
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import AddIcon from '@mui/icons-material/Add';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionIcon from '@mui/icons-material/Description';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Close, Delete, DeleteOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function Video() {
    const navigate = useNavigate();
  const [category, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  const top100Films = [
    { title: 'Sean Strickland'},
    { title: 'Paulo Costa'},
    { title: 'Glover Texteria'},
    { title: 'Johnny Walker'},
    { title: 'Kai Kara France'},
  ]

  const categoryAuto = [
    { title: 'Orchard'},
    { title: 'Packhouse'},
    { title: 'Shop'},
    { title: 'Paddock'},
  ]

  const columns = [
    { 
        field: 'name', 
        headerName: 'Full Name', 
        width: 400,
    },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'phone', headerName: 'Phone', width: 250 },

    {
        field: 'actions',
        type: 'actions',
        width: 75,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Close />}
            label="Edit"
          />,
          <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={handleOpen3}
        />,
        ],
      },

  ];
  
  const rows = [
    { id: 1, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567' },
    { id: 2, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567'  },
    { id: 3, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567' },
    { id: 4, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567' },
    { id: 5, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567' },
    { id: 6, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567' },
    { id: 7, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567'  },
    { id: 8, name: 'Sean Strickland', email: 'sean@gmail.com', phone: '021 1234 567'  },
  ];




  
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px', mb: 10}}>
        <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href="/bayley">
                    Dashboard
                </Link>
                <Link underline="hover" color="inherit" href="/bayley/inductions">
                    Inductions
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>Watch</Typography>
                <Button onClick={handleOpen1} variant="contained" startIcon={<AddIcon />}>
                    Add
                </Button>
            </Box>
            <Paper sx={{ boxSizing: 'border-box', mb: 2}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '4px 4px 0 0', p: 2}}>
                    <Box align="left" sx={{ width: '100%'}}>
                        <Typography>Search</Typography>
                        <TextField 
                            sx={{ width: '100%'}} 
                            size='small' 
                            id="outlined" 
                            variant="outlined" 
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>    
                </Box>
                <DataGrid
                            sx={{ border: '0px'}}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            autoHeight
                        />
            </Paper>
            <Box>
                <Button variant='contained' sx={{mr: 2}} onClick={() => {navigate("/bayley/watch/video")}}>
                    watch
                </Button>
            </Box>
        </Box>
        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                  <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Employee Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <Typography>Full Name</Typography>
                            <Autocomplete
                                freeSolo
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={top100Films.map((option) => option.title)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='First Name & Last Name'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Email'
                                multiline
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                multiline
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained'>Add</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                <Box sx={{ boxSizing: 'border-box', position: 'relative'}}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Employee Details</Typography>
                        <IconButton>
                            <Close onClick={handleClose1}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                        <Typography>Full Name</Typography>
                            <Autocomplete
                                freeSolo
                                defaultValue='Sean Strickland'
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                options={top100Films.map((option) => option.title)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='First Name & Last Name'
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Email'
                                multiline
                                defaultValue='sean@gmail.com'
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography>Phone</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Contact Phone'
                                multiline
                                defaultValue='021 1234 567'
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained'>Save</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box>
                        <Typography sx={{mb: 1}}>Questions & Answers</Typography>
                        <Box width='100%' sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                            
                            <TextField 
                                sx={{ width: '12%'}} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Order'
                                defaultValue='1'
                            /> 
                            <TextField 
                                sx={{ width: '85%'}} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Question Text'
                                multiline
                            /> 
                        </Box>
                        <Box>
                            <Box width='100%' sx={{display: 'flex', justifyContent: 'space-between'}}>
                                
                                <Checkbox defaultChecked sx={{ width: '12%'}}/>
                                <TextField 
                                    sx={{ width: '85%'}} 
                                    size='small' 
                                    id="outlined" 
                                    variant="outlined" 
                                    placeholder='Answer Text'
                                />
                            </Box>
                            <IconButton sx={{ml: 8, mb: 2}}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <Button variant='contained' sx={{mb: 2}}>add question</Button>
                        <Box align='right'>
                            <Button variant='contained' >save</Button>
                        </Box>
                        
                    </Box>
                </Box>
            </Modal>
    </Box>
  )
}

export default Video