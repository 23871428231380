import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, Modal, Select, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, IconButton, Autocomplete, Snackbar, Alert } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import { useNavigate, useParams } from 'react-router';
import { Close } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function LibraryV2() {
    const [row, setRows] = useState([]);
    const [form, setForm] = useState([]);
    const [video, setVideo] = useState([]);
    const [organisation, setOrganisation] = useState([]);
    const params = useParams();
    const org = localStorage.getItem('org');
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleClose1 = () => setOpen1(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const [new_video_path, setNewVideoPath] = useState("");
    const [new_thumbnail_path, setNewThumbnailPath] = useState("");
    const [new_video_title, setNewVideoTitle] = useState("");
    const [new_video_description, setNewVideoDescription] = useState("");
    const [new_org, setNewOrg] = useState("");
    const [update_video_path, setUpdateVideoPath] = useState("");
    const [update_thumbnail_path, setUpdateThumbnailPath] = useState("");
    const [update_video_title, setUpdateVideoTitle] = useState("");
    const [update_video_description, setUpdateVideoDescription] = useState("");
    const [update_org, setUpdateOrg] = useState("");
    const [selectedOrganisation, setSelectedOrganisation] = useState("");

        // Creating Video Error Alert
        const [open6, setOpen6] = React.useState(false);
        const handleOpen6 = () => setOpen6(true);
        const handleClose6 = (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
      
          setOpen6(false);
        };

    const createVideo = async (e) => {
      e.preventDefault();
      if (new_video_title === "") {
        setOpen6(true);
      } else if (new_video_description === "") {
        setOpen6(true);
      } else if (new_video_path === "") {
        setOpen6(true);
      }  else if (new_thumbnail_path === "") {
        setOpen6(true);
      }  else if (new_org === "") {
        setOpen6(true);
      }  else {

      
      try {
          const body = { new_video_path,new_thumbnail_path,new_video_title,new_video_description, new_org };
          console.log(body);
        const response = await fetch(
          `${localStorage.getItem("url")}/api/addvideo`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
        window.location.reload();
      } catch (err) {
        console.error(err.message);
      }
    }
    };

    const updateVideo = async (e) => {
      if (update_video_title === "") {
        setOpen6(true);
      } else if (update_video_description === "") {
        setOpen6(true);
      } else if (update_video_path === "") {
        setOpen6(true);
      }  else if (update_thumbnail_path === "") {
        setOpen6(true);
      }  else if (update_org === "") {
        setOpen6(true);
      }  else {

      e.preventDefault();
         
          try {
            const body = { update_video_path,update_thumbnail_path,update_video_title,update_video_description, update_org };
            console.log(body)
            const response = await fetch(
              `${localStorage.getItem("url")}/api/updateVideo/${video[0].video_id}`,
              {
                method: "PUT",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(body),
              }
            );
              
            window.location.reload();
          } catch (err) {
            console.error(err.message);
          }
      
        }
  };

    const getOrgUsers = async () => {
        try{
            const response = await fetch(
                `${localStorage.getItem("url")}/api/test2/${localStorage.getItem("org_id")}`
            );
            const jsonData = await response.json();
                console.log(jsonData);
            setRows(jsonData);
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getForms = async (video) => {
        try{
            console.log(params.video)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/orgvideo/${video}`
            );
            const jsonData = await response.json();
                console.log(jsonData)
            setForm(jsonData);
            setOpen(true)

 
            
           
            
        } catch (err){
            console.log(err.message);
        }

      };

        const getVideo = async (id) => {
          try{
              console.log(params.id)
              const response = await fetch(
                  `${localStorage.getItem("url")}/api/video/${id}`
              );
              const jsonData = await response.json();
                  console.log(jsonData)
              setVideo(jsonData);
              setSelectedOrganisation(organisation.find(organisation => organisation.id === video[0].org_id));
              console.log(video)
              setOpen3(true);
   
              
             
              
          } catch (err){
              console.log(err.message);
          }
        
       
      };

      const getEdit = async(id, title, description, video_path, thumbnail_path, org_id) => {
        getVideo(id);
        setUpdateVideoPath(video_path);
        setUpdateThumbnailPath(thumbnail_path);
        setUpdateVideoTitle(title);
        setUpdateVideoDescription(description);
        setUpdateOrg(org_id);
        
        console.log(selectedOrganisation)
        
      }

      const getAllOrganisations = async (video) => {
        try{
            console.log(params.video)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/all_organisation/`
            );
            const jsonData = await response.json();
                console.log(jsonData)
            setOrganisation(jsonData);

 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };
      

      useEffect(() => {
        getOrgUsers();
        getAllOrganisations();
       }, []);

  const [category, setAge] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleModalVideo = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: 24,
    backgroundColor: 'rgb(245,245,245)',
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
  };

  {/* OLD SEARCH
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(row);
 
  // exclude column list from filter
  const excludeColumns = ["video_id", "video_path", "thumbnail_path", "org_id",];
 
  // handle change event of search input
  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };
 
  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toString().toLowerCase().trim();
    if (lowercasedValue === "") setData(row);
    else {
      const filteredData = row.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }
*/}

    const [query, setQuery] = useState("")

    row.sort(function (a, b) {
      if (a.video_title < b.video_title) {
        return -1;
      }
      if (a.video_title > b.video_title) {
        return 1;
      }
      return 0;
    });

    const content =  row.filter(post => {
          if (query === '') {
            return post;
          } else if (post.video_title.toLowerCase().includes(query.toLowerCase())) {
            return post;
          }
        }).map((post) => ( 
  
    <Grid xs={4} >

        <Card key={post.video_id}>
            <CardActionArea >
                <Box onClick={() => (getForms(post.video_id)) } sx={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <img src={post.thumbnail_path} width='100%' height='300px' style={{ objectFit: 'cover', objectPosition: 'center' }}/>
                </Box>
                
                <CardContent sx={{ height: '150px', position: 'relative', mb: 2}}>
                <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center'}}>
                    {post.video_title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {post.video_description}
                </Typography>
                <Box sx={{ bottom: '0', position: 'absolute'}}>
                  <IconButton onClick={() => (getEdit(post.video_id, post.video_title, post.video_description, post.video_path, post.thumbnail_path, post.org_id)) }>
                    <EditIcon />
                  </IconButton>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
    
  ));

                    

  return (
    <Box sx={{ pl: '60px', width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px'}}>
        <Snackbar open={open6} autoHideDuration={6000} onClose={handleClose6} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose6}>
                Enter all Video information.
              </Alert>
            </Snackbar>
        <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href={`/test/users`}>
                    Dashboard
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Typography  variant="h4" sx={{ fontWeight: '600'}}>Video Library</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant='contained' onClick={handleOpen2}>CREATE VIDEO</Button>
                </Box>
            </Box>
            <Paper sx={{ p: 4, boxSizing: 'border-box', mb: 10}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '4px 4px 0 0'}}>
                    <Box align="left" sx={{mb: 3, width: '100%'}}>
                        <Typography>Search</Typography>
                        <TextField 
                            sx={{ width: '100%'}} 
                            size='small' 
                            id="outlined"
                            variant="outlined" 
                            onChange={event => setQuery(event.target.value)}
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
             <Grid container spacing={2} sx={{ pb: 4}}>
                {content}
            </Grid>
            </Paper>
        </Box>
        <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModalVideo}>
                  <IconButton onClick={() => setOpen(false)} sx={{position: 'absolute', zIndex: 10, top: 0, right: 0, }}>
                      <CloseRoundedIcon sx={{ fontSize: 40, color: 'white' }}/>
                    </IconButton>
                  <Box sx={{ position: 'relative', paddingTop: '56.25%'}}>
                    {form.map((post) => (
                        <ReactPlayer
                      key={post.video_path}
                      className='react-player'
                      url={post.video_path}
                      width='100%'
                      height='100%'
                      controls={true}
                      style={{ position: 'absolute', top: '0', left: '0' }}
                    />
                    ))}
                  </Box>

                </Box>
            </Modal>
        <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Video Details</Typography>
                        <Close onClick={handleClose2}/>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Title'
                                defaultValue=''
                                onSelect={(e) => setNewVideoTitle(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Description'
                                defaultValue=''
                                multiline
                                onSelect={(e) => setNewVideoDescription(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video URL</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video URL'
                                defaultValue=''
                                onSelect={(e) => setNewVideoPath(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Thumbnail</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Thumbnail'
                                defaultValue=''
                                onSelect={(e) => setNewThumbnailPath(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                        <Typography>Organisation</Typography>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setNewOrg(b.id)}
                                options={organisation}
                                getOptionLabel={(organisation) => organisation.organisation_name }
                                
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.category_id}
                                    placeholder='Organisation'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={createVideo}>Create</Button>
                        </Box>
                </Box>
        </Modal>
        <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>Video Details</Typography>
                        <Close onClick={handleClose3}/>
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Title</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Title'
                                name={video[0]?.video_title}
                                defaultValue={video[0]?.video_title}
                                onSelect={(e) => setUpdateVideoTitle(e.target.value)}
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Description</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Description'
                                name={video[0]?.video_description}
                                defaultValue={video[0]?.video_description}
                                multiline
                                onSelect={(e) => setUpdateVideoDescription(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video URL</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video URL'
                                name={video[0]?.video_path}
                                defaultValue={video[0]?.video_path}
                                onSelect={(e) => setUpdateVideoPath(e.target.value)}
                            />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Video Thumbnail</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Video Thumbnail'
                                name={video[0]?.thumbnail_path}
                                defaultValue={video[0]?.thumbnail_path}
                                onSelect={(e) => setUpdateThumbnailPath(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                        <Typography>Category</Typography>
                            <Autocomplete
                                
                                size='small'
                                sx={{ width: '100%'}} 
                                disableClearable
                                onChange={(a, b) => setUpdateOrg(b.id)}
                                options={organisation}
                                getOptionLabel={(organisation) => organisation.organisation_name }
                                defaultValue={selectedOrganisation}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={params.category_id}
                                    placeholder='Video'
                                          
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                />
                                )}
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained' onClick={updateVideo}>Update</Button>
                        </Box>
                </Box>
        </Modal>
    </Box>
  )
}

export default LibraryV2
