import React, { useEffect, useState, useRef } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography, Button, FormControl, Paper, Breadcrumbs, Link, Rating, Snackbar, Alert, Modal, CircularProgress, IconButton } from '@mui/material/'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate, useParams } from 'react-router-dom'
import { groupBy, isNull } from "lodash";
import Moment from 'moment';
import SignaturePad from "react-signature-canvas";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Induction() {
    const navigate = useNavigate();
    const params = useParams();
    const [inductions, setInductions] = useState([]);
    const [user, setUser] = useState([]);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);

    const sigCanvas = useRef({});
  
    /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current.clear();

    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
      const getUserInfo = async () =>{
        try{
          const response = await fetch(
              `${localStorage.getItem("url")}/api/client_user/${localStorage.getItem("id")}`
          );
          const jsonData = await response.json();
       
          setUser(jsonData);
          console.log(jsonData)
          
         
          
      } catch (err){
          console.log(err.message);
      }
      
    }; 

    const getInduction = async () =>{
      try{
        const response = await fetch(
            `${localStorage.getItem("url")}/api/client_user_induction/${params.induction}`
        );
        const jsonData = await response.json();
       const questions = [];
       const answers = [];
        for(var i = 0; i < jsonData.length; i++){

        
      
        console.log("hello")
        console.log(jsonData[i].correct_answer)
        console.log(jsonData[i].question_text)
        answers.push(jsonData[i].correct_answer)
        questions.push(jsonData[i].question_text)
      
       }
        setInductions(jsonData);
        setAnswers(answers);
        setQuestions(questions);
        
        }
         catch (err){
            console.log(err.message);
        }
      };

      const handleSubmit = () => {
            var error = "";
             const id = inductions[0].induction_id
             var correct_answers_length = inductions.length
             var correct_answers_array = []
             inductions.forEach((induction) => {
            const arr = induction.answer_text.split(",")
            const r = induction.question_id
            console.log(induction.question_id)
            const answer = document.getElementsByName(induction.question_id);
            console.log(answer.length)
                  
                 for(var i = 0; i < answer.length; i++){
                     console.log(answer[i].checked)
                     console.log(answer[i].value)
                     console.log(induction.correct_answer)
                    
                     
                      if(answer[i].value == induction.correct_answer && answer[i].checked){
                            
                             correct_answers_array.push(answer[i].value);
     
                            
                         }       
                         else{
                          
                             
                         }  
                 }})

             console.log(correct_answers_array.length);
             console.log(correct_answers_length);
             if(correct_answers_array.length === correct_answers_length){
                 completeInduction();
                 
             }else{
                 setOpen4(true);
             }
 
         };


         const completeInduction = async () => {
            console.log(questions)
            console.log(answers)
                    const user_id = user[0].id
                    const induction = inductions[0].induction_id   
                    const current = new Date();
                    const datetime = Moment(current).format('YYYY-MM-DD-H-mm-s');
                    console.log(Moment(current).format('YYYY-MM-DD-H:mm:ss'))
                    const sign = sigCanvas.current.getTrimmedCanvas().toDataURL();
                    const q = questions;
                    const a = answers;
                    const org = localStorage.getItem("org_id")
                    const body = { user_id,induction,datetime, org, sign, q, a};
                    console.log(body)
                    console.log(Moment(current).format('YYYY-MM-DD'))

                    const response =  fetch(
                        `${localStorage.getItem("url")}/api/completeInductionSingle`,
                        {
                          method: "POST",
                          headers: { "content-type": "application/json" },
                          body: JSON.stringify(body),
                        }
                      )
                        setOpen3(true);

    
          
                
            
          }
    
          const finishInduction = () => {
            navigate("/")
           
           
          
        }

useEffect(() => {
    getInduction();
    getUserInfo();
    
    
    }, []);
    
    const styleModal = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        width: {
            xs: 300,
            sm: 450,
          },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

    if(inductions.length){
        return (
            
            <Box 
            sx={{ 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems:'center', 
                boxSizing: 'border-box'
            }}>
                <Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{  
                        width: {
                            xs: 300,
                            sm: 500,
                            md: 800,
                            lg: 1000,
                            xl: 1200,
                        },  height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="rgb(245,245,245)" href="/user">
                                Dashboard
                            </Link>
                        </Breadcrumbs>
                    <Typography variant='h4' sx={{ mb: 3, color: 'white', fontSize: {
                xs: '24px',
                sm: '26px',
                md: '30px',
                lg: '32px',
                xl: '34px'
              }}}>{inductions[0].induction_title}</Typography>
                    </Box>
                </Box>
                    <Box 
                    sx={{ 
                        width: {
                            xs: 300,
                            sm: 500,
                            md: 800,
                            lg: 1000,
                            xl: 1200,
                        }, 
                        mb: 10,
                        mt: 2
                        }}>
                        <Paper sx={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column' , p: 4}}>
                            <Typography variant='h6' sx={{mb: 2 }}>Instructions</Typography>
                            <Typography sx={{mb: 3 }}>{inductions[0].induction_description}</Typography>
                            {inductions[0].video_path === null
                            ?
                                <Box>
                            </Box>
                            :
                            <Box sx={{ position: 'relative', pt: '56.25%', mb: 2}}>
                                <ReactPlayer
                                    style={{ position: 'absolute', top: '0', left: '0' }}
                                    className='react-player'
                                    url={inductions.length ? inductions[0].video_path : 'https://vimeo.com/780541642'}
                                    width='100%'
                                    height='100%'
                                    controls='true'
                                />
                            </Box>
                            }

                            <Typography variant='h6' sx={{mb: 2 }}>Questions</Typography>
                           
                                 {inductions.map((induction) => 
                                 <FormControl sx={{mb: 3}}>
                                <FormLabel sx={{ color: 'black'}} id="demo-radio-buttons-group-label">{induction.question_text}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    // onChange={handleChange}
                                 
                                >
                                  
    
                                        {induction.answer_text.split(',').map(function (answer , index){
                                            return (
                                                <FormControlLabel name={induction.question_id} value={answer} control={<Radio />} label={answer}/>
                                            )
                                        })}
    
                                    
                                    
                                </RadioGroup>
                            </FormControl>)}
                                
                            <Box sx={{ backgroundColor: '#FFFF', border: '1px solid black'}}>
                                <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                    className: "signatureCanvas"
                                    }}
                                />


                            </Box>
                                    <Box>
                                                                    <IconButton color="primary" onClick={clear} sx={{pb: 4}}>
                                  <CloseRoundedIcon />
                                </IconButton>
                                    </Box>

                            <Box>
                                <Button onClick={handleSubmit} variant='contained' >Submit</Button>
                            </Box>
                        </Paper>
                        <Button href={`/`} variant='contained' sx={{mt: 3}}>back</Button>
                        <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
                  <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                    One or more answers are incorrect.
                  </Alert>
                </Snackbar>
                    </Box>
                    <Modal
                    open={open3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styleModal}>
                        <Typography variant='h5' mb={1}>Induction Completed</Typography>
                        <Typography variant='body1' color={'GrayText'} mb={3}>All questions have been answered correctly!</Typography>
                        <Button onClick={finishInduction} sx={{width: '100%'}} variant='contained'>Finish Induction</Button>
                    </Box>
            </Modal>
                </Box>
              )
     } else{
        return(
<Box sx={{width: '100%', backgroundColor: '#66BC44', display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{  
                        width: {
                            xs: 300,
                            sm: 500,
                            md: 800,
                            lg: 1000,
                            xl: 1200,
                        },  height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="rgb(245,245,245)" href="/user">
                        Dashboard
                    </Link>
                </Breadcrumbs>
                <CircularProgress sx={{color: '#FFFFFF', mb: 3}}/>
            </Box>
        </Box>
        )
     }
    }

export default Induction
