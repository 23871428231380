import Logout from '@mui/icons-material/Logout'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Logo from '../../Assets/bayley.png'
import SettingsIcon from '@mui/icons-material/Settings';

function UserMenu() {
  return (
    <div>
        <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', backgroundColor: '#65B743', paddingY: 1}}>
            <Box sx={{ width: '1200px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <img src={Logo} width="100px" />
                <Box>
                    <IconButton sx={{ color: 'white'}}>
                        <Logout />
                    </IconButton>
                    <IconButton sx={{ color: 'white'}}>
                        <SettingsIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
        <Outlet />
    </div>

  )
}

export default UserMenu