import React, { Fragment,useState, useEffect } from 'react';
import { Box, Container, Typography, InputLabel, Select, MenuItem, FormControl, TextField, Button, Modal, Card, CardActionArea, CardContent, Autocomplete, IconButton, Popover, Link, Breadcrumbs, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, } from '@mui/material/'
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { GridActionsCellItem } from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate, useParams } from 'react-router';
import Close from '@mui/icons-material/Close';
import { HexColorPicker, HexColorInput } from "react-colorful";

import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Unstable_Grid2';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreVert from '@mui/icons-material/MoreVert';
import RestartAlt from '@mui/icons-material/RestartAlt';

function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          boxSizing: 'border-box',
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant='h6'>Inductions</Typography>
        <GridToolbarQuickFilter 
        sx={{ 
          width: {
            xs: '60%',
            sm: '75%',
            md: '80%',
            lg: '90%'
          },
          }} />
      </Box>
    );
  }

function Users() {
  
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
   

    const [color, setColor] = useState("#aabbcc");

    const navigate = useNavigate();
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleClose1 = () => setOpen1(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const [rows, setRows] = useState([]);
    const [user, setUser] = useState([]);
    const [category, setAge] = React.useState('');
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [checkboxSelection, setCheckboxSelection] = React.useState(true);

    const params = useParams();
    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const getInductions = async () => {
        try{
            console.log(params.user)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/userInductions/${params.user}}`
            );
            const jsonData = await response.json();
         
       
            setRows(jsonData);
            console.log(jsonData)
 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      const getUser2 = async () => {
        try{
            console.log(params.user)
            const response = await fetch(
                `${localStorage.getItem("url")}/api/orgUsers2/${params.user}}`
            );
            const jsonData = await response.json();
         
       
            setUser(jsonData);
          console.log(jsonData)
 
            
           
            
        } catch (err){
            console.log(err.message);
        }
        
       
      };

      
    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

          
    useEffect(() => {
        getUser2();
        getInductions();
       }, []);

      
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

      const columns = [
        { 
        field: 'induction_title', 
        headerName: 'Induction', 
        width: 500,
        renderCell: (cellValues) => {
            console.log(cellValues)
              return (
                  <Box   onClick={() => {getUser(cellValues.row.id)}} sx={{ cursor: 'pointer'}}>
                      {cellValues.value}
                  </Box>
                      
              )
          }
    },
        { field: 'date_completed', headerName: 'Date', width: 100 },
        { field: 'time_completed', headerName: 'Time', width: 100 },

      ];

      const columnGroupingModel = [
        {
          groupId: 'Questions',
          children: [{ field: 'question_text' }],
        },
        {
          groupId: 'Answers',
          children: [
            
            { field: 'question_answer' },
          ],
        },
      ];

      
      const getUser = async (id) => {
     

        console.log(id);
        navigate(`${id}`)
       
       
      
    }


    return(

        <Box sx={{ pl: '60px', width: '100%', display: 'flex', justifyContent: 'center', boxSizing: 'border-box'}}>
        <Box sx={{ width: '1200px'}}>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                <Link underline="hover" color="inherit" href="/bayley">
                    Dashboard
                </Link>
            </Breadcrumbs>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2 }}>
                <Box >
                    {user.length 
                    ?
                        <Box sx={{display: 'flex'}}>
                            <Typography  variant="h4" sx={{ fontWeight: '600', pr: 2}}>{user[0].firstname}</Typography>
                            <Typography  variant="h4" sx={{ fontWeight: '600'}}>{user[0].lastname}</Typography>
                        </Box>
                    

                    :
                    <Typography  variant="h4" sx={{ fontWeight: '600'}}>loading..</Typography>
                }
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant='contained' >edit</Button>
                </Box>
            </Box>
            <Box sx={{ width: '100%', mb: 2}}>
                <Paper>
                    <DataGrid
                        sx={{ border: '0px' }}
                        rows={rows || {id: "loading"}}
                        columns={columns}
                        pageSize={10}
                        selectionModel={selectionModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        autoHeight
                        components={{  Toolbar: QuickSearchToolbar }}
                        checkboxSelection={checkboxSelection}
                        rowHeight={70}
                        getRowId={(row) =>  generateRandom()}
                    />
                </Paper>
              </Box>
        </Box>

        <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>User Details</Typography>
                        <Close />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Full Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Full Name'
                                defaultValue=''
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue=''
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone Number</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Phone'
                                defaultValue=''
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained'>Create</Button>
                        </Box>
                </Box>
        </Modal>
        <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                                <Box sx={styleModal}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3}}>
                        <Typography variant='h6'>User Details</Typography>
                        <Close />
                    </Box>
                        <Box align="left" mb={1}>
                            <Typography>Full Name</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Full Name'
                                defaultValue='Sean Strickland'
                        />
                        </Box>
                        <Box align="left" mb={1}>
                            <Typography>Email</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Email'
                                defaultValue='sean@example.co.nz'
                            />
                        </Box>
                        <Box align="left" mb={3}>
                            <Typography>Phone Number</Typography>
                            <TextField 
                                sx={{ width: '100%' }} 
                                size='small' 
                                id="outlined" 
                                variant="outlined" 
                                placeholder='Phone'
                                defaultValue='021 123 4567'
                            />
                        </Box>
                        <Box align='right'>
                            <Button variant='contained'>Create</Button>
                        </Box>
                </Box>
        </Modal>
    </Box>

    
    )


    

  
}

export default Users