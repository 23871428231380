import React, { Fragment,useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { Box, Container, Typography,CircularProgress, Button, Modal, Select,Snackbar, Alert, TextField, InputAdornment, FormControl, MenuItem, Checkbox, Paper, Breadcrumbs, Link, Autocomplete, IconButton } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Thumbnail from '../../Assets/thumbnail.jpg'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Close from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { ConstructionOutlined, WindowSharp } from '@mui/icons-material';
import { exit } from 'process';
import Moment from 'moment';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { groupBy, isNull } from "lodash";
import { useNavigate } from 'react-router';


function Indcution1() {
    const navigate = useNavigate()
    const [inductions, setInductions] = useState([])
    const [usersWatching, setUsersWatching] = useState([])
    const [usersStillWatching, setUsersStillWatching] = useState([])
    const [currentUser, setCurrentUser] = useState("")
    const [categoryCount, setCategoryCount] = useState(0)
    const [arr1, setArr1] = useState([]);
    const [arr2, setArr2] = useState([]);
    const [open3, setOpen3] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [count, setCount] = useState(0)
    var test = inductions.length;
    // const handleChange = (event) => {
    //     inductions.forEach((induction) => {
    //        selectedValue.push(event.target.value);
    //     })
      
    // };
    

      const handleOpen3 = () => setOpen4(true);
      const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
      const handleOpen4 = () => setOpen4(true);
    const handleOpen5 = () => setOpen5(true);
  
    const handleClose4 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen4(false);
      };
        const handleClose5 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen5(false);
      };

      const styleModal = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        width: {
            xs: 300,
            sm: 450,
          },
        bgcolor: 'white',
        borderRadius: '4px',
        boxShadow: 24,
        p: 4,
      };

    

      const getCategoryCount = async () => {
        try{
            
            const response = await fetch(
                `${localStorage.getItem("url")}/api/category/${localStorage.getItem("org_id")}/${localStorage.getItem("category")}`

            );
            const jsonData = await response.json();
          
            setCategoryCount(jsonData.length);
            console.log(jsonData)
       
           
            
        } catch (err){
            console.log(err.message);
        }
        
    
      }
 


    

      const getInductions = async () => {
        try{
            
            const response = await fetch(
                `${localStorage.getItem("url")}/api/all_inductions/${localStorage.getItem("org_id")}/${localStorage.getItem("category")}`

            );


            const jsonData = await response.json();
       
           setInductions(jsonData);
           console.log(jsonData)
           
            }

          
            
       
           
            
         catch (err){
            console.log(err.message);
        }
        
    
       
       
      };
      




      const handleSubmit = () => {
         if (count == 0) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(1)
            console.log(count)
            }
        } else if (count == 1) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(2)
            console.log(count)
            }
        } else if (count == 2) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(3)
            console.log(count)
            }
        } else if (count == 3) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(4)
            console.log(count)
            }
        } else if (count == 4) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(5)
            console.log(count)
            }
        } else if (count == 5) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(6)
            console.log(count)
            }
        } else if (count == 6) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(7)
            console.log(count)
            }
        } else if (count == 7) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(8)
            console.log(count)
            }
        } else if (count == 8) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(9)
            console.log(count)
            }
        } else if (count == 9) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(10)
            console.log(count)
            }
        } else if (count == 10) {
            if (count+1 == inductions.length) {
                setOpen3(true);
            } else {
            setCount(11)
            console.log(count)
            }
        }
        };

      const finishInduction = () => {
        navigate("/users")
       
       
      
    }



      useEffect(() => {
       getInductions();
       getCategoryCount();
      
       }, []);

       



    return (
        
        <Box 
        sx={{
            pl: {
                xs: '0px',
                lg: '60px',
            },
            pt: {
                xs: 6,
                lg: 0,
            },  
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            boxSizing: 'border-box'
        }}>
        
                <Box 
                sx={{ 
                    width: {
                        xs: 300,
                        sm: 500,
                        md: 800,
                        lg: 1000,
                        xl: 1200,
                    }, 
                    mb: 10
                    }}>
                <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
                    <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                        Please select people to start an Induction.
                    </Alert>
                </Snackbar>
                <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href="/test/users">
                            Dashboard
                        </Link>
                        <Link underline="hover" color="inherit" href="/test/inductions">
                            Inductions
                        </Link>
                    </Breadcrumbs>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                        {inductions.length ?
                        <Typography  variant="h4" sx={{ fontWeight: '600'}}>{inductions[count].induction_title}</Typography>
                        :
                        <CircularProgress sx={{color: '#757575'}}/>
                    }
                        
                    </Box>
                    <Paper sx={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column' , p: 4}}>
                        {inductions.length ?
                        <Box>
                            <Box sx={{ position: 'relative', pt: '56.25%', mb: 2}}>
                                <ReactPlayer
                                    style={{ position: 'absolute', top: '0', left: '0' }}
                                    className='react-player'
                                    url={inductions[count].video_path}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                />
                            </Box>
                            <Box align='right'>
                                <Button onClick={handleSubmit} variant='contained' >next</Button>
                            </Box>
                        </Box>
                        :

                        <CircularProgress sx={{color: '#757575'}}/>
                    }

                    </Paper>
                    <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose4} sx={{ ml: 7 }}>
              <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose4}>
                One or more answers are incorrect.
              </Alert>
            </Snackbar>
                </Box>
                <Modal
                open={open3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Typography variant='h5' mb={1}>Induction Completed</Typography>
                    <Typography variant='body1' color={'GrayText'} mb={3}>All Users have been inducted.</Typography>
                    <Button onClick={finishInduction} sx={{width: '100%'}} variant='contained'>Finish Induction</Button>
                </Box>
        </Modal>
            </Box>
          )
    }

export default Indcution1
