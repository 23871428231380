import { Box, Breadcrumbs, Card, CardActionArea, CardContent, Link, Paper, Typography } from '@mui/material'
import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router';
import { SupportAgent } from '@mui/icons-material';

function FAQ() {
    const navigate = useNavigate();

  return (
    <Box     
        sx={{
            pl: {
                xs: '0px',
                lg: '60px',
            },
            pt: {
                xs: 6,
                lg: 0,
            },  
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            boxSizing: 'border-box'
        }}>
        <Box         
            sx={{ 
                width: {
                    xs: 300,
                    sm: 500,
                    md: 800,
                    lg: 1000,
                    xl: 1200,
                }, 
                mb: 10
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 2}}>
                <Box>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop: 5,}}>
                        <Link underline="hover" color="inherit" href={`/test/users`}>
                            Dashboard
                        </Link>
                    </Breadcrumbs>
                    <Typography  variant="h4" 
                    sx={{ 
                    fontWeight: '600',
                    fontSize: {
                        xs: 25,
                        md: 30,
                    },
                    }}>
                        Upskill Learning</Typography>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ pb: 2}}>
                <Grid xs={4} >
                    <Card onClick={() => {navigate("/learning/video-library")}}>
                        <CardActionArea >
                            <CardContent sx={{ height: '150px', position: 'relative', mb: 2}}>
                                <Typography variant="body2" color={"GrayText"}>Get started guide.</Typography>
                                <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center', fontWeight: '600'}}>
                                    Video Library
                                </Typography>
                                <Typography variant="body1">
                                    How to get your staff video learning.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4} >
                    <Card onClick={() => {navigate("/learning/users")}}>
                        <CardActionArea >
                            <CardContent sx={{ height: '150px', position: 'relative', mb: 2}}>
                                <Typography variant="body2" color={"GrayText"}>Get started guide.</Typography>
                                <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center', fontWeight: '600'}}>
                                    Users
                                </Typography>
                                <Typography variant="body1" >
                                    Setup users for inductions.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4} >
                    <Card onClick={() => {navigate("/learning/inductions")}}>
                        <CardActionArea >
                            <CardContent sx={{ height: '150px', position: 'relative', mb: 2}}>
                                <Typography variant="body2" color={"GrayText"}>Get started guide.</Typography>
                                <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center', fontWeight: '600'}}>
                                    Inductions
                                </Typography>
                                <Typography variant="body1">
                                    How to setup inductions for efficient and effective onboarding.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid xs={4} >
                    <Card onClick={() => {navigate("/learning/completing-inductions")}}>
                        <CardActionArea >
                            <CardContent sx={{ height: '150px', position: 'relative', mb: 2}}>
                                <Typography variant="body2" color={"GrayText"}>Get started guide.</Typography>
                                <Typography gutterBottom variant="h5" sx={{ display: 'flex', alignItems: 'center', fontWeight: '600'}}>
                                    Completing Inductions
                                </Typography>
                                <Typography variant="body1" >
                                How to easily onboard your staff.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            <Paper sx={{ p: 4, display: 'flex', alignItems: 'center' }}>
                <Box>
                    <SupportAgent sx={{ fontSize: '50px', mr: 2}}/>
                </Box>
                <Box>
                    <Typography variant='h5' sx={{fontWeight: '600'}}>Still have questions?</Typography>
                    <Typography variant='body1' onClick={() => window.location = 'mailto:info@infinitedigital.co.nz?subject=Enquiry'} sx={{cursor: 'pointer'}}>Email us info@infinitedigital.co.nz</Typography>
                </Box>
            </Paper>
        </Box>
    </Box>
  )
}

export default FAQ